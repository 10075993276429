import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import React, { useEffect, useState, useContext } from 'react';
import { Context } from "../context/WalletContext";
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/Button";
import Popup from "../components/Popup";

const BetaTester = () => {
    const navigate = useNavigate();
    const { state } = useContext(Context);

    const [matchesData, setMatchesData] = useState([]);
    const [betaTester, setBetaTester] = useState({});
    const [leaderSubjects, setLeaderSubjects] = useState(null);
    const [hotNews, setHotNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [currentTable, setCurrentTable] = useState('matches');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = state?.userWallet?.string;
                const userData = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${user}`);
                setBetaTester(userData.data);
                const hotNewsResponse = await axios.get('https://api.qorbiworld.com/v1/hotnews');
                setHotNews(hotNewsResponse.data);
                if (userData?.data?.testerGroup) {
                    const response = await axios.get(`https://api.qorbiworld.com/v1/beta-testers/${userData.data.username}`);
                    const sortedData = response.data.sort((a, b) => new Date(b._id) - new Date(a._id));
                    setLoading(false);
                    setMatchesData(sortedData);
                } else {
                    navigate('/');
                }
                if (userData?.data?.betaLeader === true) {
                    const leaderSubjectsResponse = await axios.get(`https://api.qorbiworld.com/v1/beta-leaders/${userData.data.username}`);
                    setLeaderSubjects(leaderSubjectsResponse.data);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateTotalQorbi = () => {
        return matchesData.reduce((total, match) => total + match.matches, 0);
    };

    const calculateMonthlyEarnings = (totalEarning) => {
        const startMonth = new Date('2024-07-01');
        const currentMonth = new Date();
        const monthsDifference = (currentMonth.getFullYear() - startMonth.getFullYear()) * 12 + (currentMonth.getMonth() - startMonth.getMonth());
        return totalEarning + (monthsDifference * 250);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div className="beta-gamers-matches">
            <h1 className='green-title text-center'>Matches For {betaTester.username} From Team {betaTester.testerGroup}</h1>
            <div className="middle-container">
                {showPopup ? (
                    <Popup
                        onClose={() => setShowPopup(false)}
                        title="Building!!!"
                        content={
                            <>
                                <p>
                                    This option will be activated after the QORBI token is listed. You will be able to transfer to any wallet
                                </p>
                                <Button label="Ok" onClick={() => setShowPopup(false)} />
                            </>
                        }
                    />
                ) : null}
                <div className="qorbi-earnings">
                    <div className='total-qorbi'>
                        <img src={qorbi_coin} alt="Qorbi_Coin" style={{ width: 'auto', height: '50px', }} />
                        ‎ Matches Earning: <div style={{ color: "#0f0", marginLeft: 8 }}>{calculateTotalQorbi()} QORBI</div>
                    </div>
                    <div className="button-container-tester">
                        <button
                            className="greenBtn"
                            style={{ width: "55%", height: 50 }}
                            onClick={() => setShowPopup(true)}
                        >
                            Claim Your Qorbi
                        </button>
                    </div>
                    <div className="table-container">
                        {leaderSubjects && (
                            <div className="table-switch-container">
                                <button
                                    className={`btnTransparent ${currentTable === 'matches' ? 'greenBtn' : ''}`}
                                    onClick={() => setCurrentTable('matches')}
                                >
                                    Matches Table
                                </button>
                                <button
                                    className={`btnTransparent ${currentTable === 'leaders' ? 'greenBtn' : ''}`}
                                    onClick={() => setCurrentTable('leaders')}
                                >
                                    My Gamers
                                </button>
                            </div>
                        )}
                        {currentTable === 'matches' ? (
                            <table className="matches-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Matches</th>
                                        <th>Qorbi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {matchesData.map((match) => (
                                        <tr key={match._id}>
                                            <td>{match._id}</td>
                                            <td>{match.matches}</td>
                                            <td>{match.matches}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <table className="matches-table">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Matches</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderSubjects.usernames.map((user) => (
                                        <tr key={user.username}>
                                            <td>{user.username}</td>
                                            <td>{user.matches}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className='second-middle-container'>
                    <div className="total-qorbi" style={{ opacity: !leaderSubjects && 0, userSelect: !leaderSubjects && "none", pointerEvents: !leaderSubjects && "none" }}>
                        <img src={qorbi_coin} alt="Qorbi_Coin" style={{ width: 'auto', height: '50px', }} />
                        ‎ Referal Earning: <div style={{ color: "#0f0", marginLeft: 8 }}>{leaderSubjects && calculateMonthlyEarnings(leaderSubjects.totalEarning)} QORBI</div>
                    </div>
                    <div className="button-container-tester" style={{ opacity: 0, userSelect: "none", pointerEvents: "none" }}>
                        <button
                            className="greenBtn"
                            style={{ width: "55%", height: 50 }}
                            onClick={() => setShowPopup(true)}
                        >
                            Claim Your Posho
                        </button>
                    </div>
                    <div className="hot-news">
                        <h2>Hot News</h2>
                        {hotNews.map(news => (
                            <div key={news._id}>
                                <p>{news.simpleText} {news.link && <a style={{ color: "green" }} target='blank' href={news.link}>Go Now</a>}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BetaTester;
