import React from "react";

const ThankYou = () => {
  return (
    <div className="darkbg">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="green-title">Your Payment Was Successfully</h2>
              <h2 className="green-title" style={{ marginTop: 10 }}>
                Your NFT is on the way
              </h2>
            </div>
            <div className="col-12 text-center">
              <p>
                If you do not receive your NFT please open a support ticket in
                our discord.
              </p>
              <a
                href="https://discord.com/invite/tvHSEAubzn"
                target="_blank"
                className="greenBtn"
                rel="noreferrer"
              >
                Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
