import createDataContext from "./CreateDataContext";
import {
  linkAddress,
  Toast,
  environment,
  Sphereone,
  PlayFab,
} from "../utils/Global";
import { deleteData, getData, postData } from "../api/ApiRequest";
import * as web3 from "@solana/web3.js";
import axios from "axios";
import detectEthereumProvider from '@metamask/detect-provider'
import { Link } from "@imtbl/imx-sdk";
const TitleId = "f79f9";
const PlayFabURL = `https://${TitleId}.playfabapi.com/client/`;
const PlayFabURLServer = `https://${TitleId}.playfabapi.com/server/`;
const secretKeyPlayFab = "FJ8G9CXJXCTROHCH3C3QA47IDZ1OJ5XNQ8INYY93753GJTZTG7";
const host = window.location.host;
let lastWalletState;
const walletnetwork =
  host === "dev.qorbaby.com" ||
    host === "127.0.0.1:8887" ||
    host === "localhost:3000"
    ? "devnet"
    : "mainnet-beta";
const immutableApi =
  environment === "dev"
    ? "https://dev-api.qorbiworld.com/v1/"
    : "https://api.qorbiworld.com/v1/";
const authWallet = (state, action) => {
  switch (action.type) {
    case "walletLogin":
      return { ...state, userWallet: action.payload, loading: false };
    case "logout":
      return {
        userWallet: null,
        account: null,
        loading: false,
        userFab: null,
        user: null,
      };
    case "loading":
      return { ...state, loading: action.payload, loadingText: "" };
    case "loadingText":
      return { ...state, loadingText: action.payload };
    case "babiesNumber":
      return { ...state, babiesNumber: action.payload };
    case "marketplaceStatus":
      return { ...state, loading: false, marketplaceStatus: action.payload };
    case "login":
      return { ...state, loading: false, user: action.payload };
    case "loginFab":
      return { ...state, loading: false, userFab: action.payload };
    case "signup":
      return { ...state, loading: false, user: action.payload };
    case "userLogout":
      return { ...state, loading: false, user: null };
    default:
      break;
  }
};
const checkWallet = () => async () => {
  if ("ethereum" in window) {
    return true;
  } else {
    return false;
  }
};
const metamaskLogin = (dispatch) => async () => {
  try {
    const addressLocalStorage = localStorage.getItem("WALLET_ADDRESS");
    if (addressLocalStorage == null) {
      const providerMetamask = await detectEthereumProvider();
      if (providerMetamask) {
        const accounts = await providerMetamask.request({
          method: "eth_requestAccounts",
        })
        const address = accounts[0];
        // const chainId = parseInt(await providerMetamask.request({ method: 'eth_chainId' }));
        localStorage.setItem("WALLET_ADDRESS", address);
        dispatch({
          type: "walletLogin",
          payload: { string: address, name: "metamask", wallets: lastWalletState },
        });
        return { success: true, data: address };
      }
    } else {
      dispatch({
        type: "walletLogin",
        payload: { string: addressLocalStorage, name: "immutablex", wallets: lastWalletState },
      });
      return { success: true, data: addressLocalStorage };
    }
  } catch (error) {
    console.error("Error connecting to MetaMask:", error);
  }
};
const immutableLogin = (dispatch) => async () => {
  const link = new Link(linkAddress);
  const addressLocalStorage = localStorage.getItem("WALLET_ADDRESS");
  const publicKeyLocalStorage = localStorage.getItem("STARK_PUBLIC_KEY");
  if (addressLocalStorage == null && publicKeyLocalStorage == null) {
    const ImxWalletData = await link.setup({
      providerPreference: "none",
    });
    console.log("ImxWalletData: ", ImxWalletData);
    localStorage.setItem("STARK_PUBLIC_KEY", ImxWalletData.starkPublicKey);
    localStorage.setItem("WALLET_ADDRESS", ImxWalletData.address);
    dispatch({
      type: "walletLogin",
      payload: { string: ImxWalletData.address, name: "immutablex", wallets: lastWalletState },
    });
    return { success: true, data: ImxWalletData.address };
  } else {
    dispatch({
      type: "walletLogin",
      payload: { string: addressLocalStorage, name: "immutablex", wallets: lastWalletState },
    });
    return { success: true, data: addressLocalStorage };
  }
};
const LoginWallet = (dispatch) => async (opt) => {
  dispatch({ type: "loading", payload: true });
  const loginApi = async (from, name) => {
    let res = await postData("users/sign", {
      publicKey: from.publicKey.toString(),
    });
    if (res.success) {
      let token, refreshToken;
      if (res.data.newUser) {
        token = res.data.createUser.tokens.access.token;
        refreshToken = res.data.createUser.tokens.refresh.token;
      } else {
        token = res.data.user.tokens.access.token;
        refreshToken = res.data.user.tokens.refresh.token;
      }
      await localStorage.setItem("refreshtoken", refreshToken);
      await localStorage.setItem("token", "Bearer " + token);
      dispatch({
        type: "walletLogin",
        payload: { data: from, string: from.publicKey.toString(), name, wallets: lastWalletState },
      });
    } else {
      Toast.set({
        show: true,
        desc: "Error connecting to the api",
        type: "error",
      });
    }
  };
  const solflare = async () => {
    let response = await window.solflare.connect();
    let from = null;
    if (response) {
      from = window.solflare;
      await loginApi(from, "solflare");
    } else {
      Toast.set({
        show: true,
        desc: "Access to Solflare wallet not allowed",
        type: "error",
      });
    }
  };
  const phantom = async () => {
    let response = null,
      from = null;
    try {
      response = await window.solana.connect();
      if (response) {
        from = window.solana;
        await loginApi(from, "phantom");
      } else {
        Toast.set({
          show: true,
          desc: "Access to Phantom wallet not allowed",
          type: "error",
        });
      }
    } catch (e) {
      Toast.set({
        show: true,
        desc: "Access to Phantom wallet not allowed",
        type: "error",
      });
    }
  };
  if (opt) {
    if (opt === "solflare") {
      await solflare();
    } else {
      await phantom();
    }
  } else {
    Toast.set({
      show: true,
      desc: (
        <p>
          Metamask is not installed. <br />
          <a href="https://metamask.io/" target="_blank" rel="noreferrer">
            Metamask
          </a>
          {/* <br />
            <a href="https://solflare.com" target="_blank" rel="noreferrer">
              Solflare
            </a>
            <br />
            <a href="https://www.phantom.app/" target="_blank" rel="noreferrer">
              Phantom
            </a> */}
        </p>
      ),
    });
  }
  dispatch({ type: "loading", payload: false });
};
const LoginWalletSphereone = (dispatch) => (selectedWallet, wallets) => {
  lastWalletState = wallets;
  dispatch({ type: "loading", payload: true });
  dispatch({
    type: "walletLogin",
    payload: {
      string: selectedWallet.address,
      name: Sphereone,
      wallets: wallets,
    },
  });
};
const makeTransaction = (dispatch) => async (amount, wallet) => {
  dispatch({ type: "loading", payload: true });
  dispatch({
    type: "loadingText",
    payload: (
      <p>
        Getting your Qorb ready.
        <br /> Do not close your browser while we are processing. <br />
        This may cause delays in delivery
      </p>
    ),
  });
  try {
    let from, provider;
    if ("solflare" in window && wallet === "solflare") {
      await window.solflare.connect();
      provider = window.solflare;
      from = window.solflare;
    } else if ("phantom" in window && wallet === "phantom") {
      from = await window.solana.connect();
      provider = window.solana;
    } else {
      Toast.set({
        show: true,
        desc: (
          <p>
            Metamask extension is not installed. <br />
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              Metamask
            </a>
            {/* <br />
              <a href="https://solflare.com" target="_blank" rel="noreferrer">
                Solflare
              </a>
              <br />
              <a href="https://www.phantom.app/" target="_blank" rel="noreferrer">
                Phantom
              </a> */}
          </p>
        ),
      });
      dispatch({ type: "loading", payload: false });
      return { success: false };
    }
    var connection;
    if (environment === "dev") {
      connection = new web3.Connection(
        web3.clusterApiUrl(walletnetwork),
        "confirmed"
      );
      var airdropSignature = await connection.requestAirdrop(
        from.publicKey,
        Number(amount)
      );
      await connection.confirmTransaction(airdropSignature);
    } else {
      connection = new web3.Connection(
        "https://hidden-muddy-shape.solana-mainnet.quiknode.pro/d3b95bb2c0602e413eace0293df91627fa7220b5/",
        {
          wsEndpoint:
            "wss://hidden-muddy-shape.solana-mainnet.quiknode.pro/d3b95bb2c0602e413eace0293df91627fa7220b5/",
        }
      );
    }
    var transaction = new web3.Transaction().add(
      web3.SystemProgram.transfer({
        fromPubkey: from.publicKey,
        toPubkey: new web3.PublicKey(
          "5M7NzPht3EaVtfSUMcM4NFVsJepey2JTmZZWqqU3H59A"
        ),
        lamports: Number(amount),
      })
    );
    // Setting the variables for the transaction
    transaction.feePayer = from.publicKey;
    let blockhashObj = await connection.getRecentBlockhash();
    transaction.recentBlockhash = blockhashObj.blockhash;
    // Request creator to sign the transaction (allow the transaction)
    let signed = await provider.signTransaction(transaction);
    // // The signature is generated
    let signature = await connection.sendRawTransaction(signed.serialize());
    // Confirm whether the transaction went through or not
    await connection.confirmTransaction(signature);
    //Signature chhap diya idhar
    dispatch({ type: "loading", payload: false });
    return { success: true, data: signature };
  } catch (error) {
    console.error(error);
    dispatch({ type: "loading", payload: false });
    Toast.set({ type: "error", desc: error.message, show: true });
    return { success: false };
  }
};
const logOut = (dispatch) => async () => {
  const providerMetamask = await detectEthereumProvider();
  if (providerMetamask) {
    try {
      await window.ethereum.request({
        "method": "wallet_revokePermissions",
        "params": [
          {
            "eth_accounts": {}
          }
        ]
      });
    } catch (error) {
      console.error("Error revoking wallet permissions:", error);
    }
  }
  if (localStorage.getItem("WALLET_ADDRESS")) {
    localStorage.removeItem("STARK_PUBLIC_KEY");
    localStorage.removeItem("WALLET_ADDRESS");
  } else {
    await window.solana.disconnect();
  }
  dispatch({ type: "logout" });
};
const buyNFT = (dispatch) => async (fromPublicKey, NFTid, signature) => {
  dispatch({ type: "loading", payload: true });
  dispatch({
    type: "loadingText",
    payload: (
      <p>
        Getting your Qorb ready.
        <br /> Do not close your browser while we are processing. <br />
        This may cause delays in delivery
      </p>
    ),
  });
  return await postData("/shopping-cart/buy/NFT", {
    fromPublicKey,
    NFTid,
    signature,
  })
    .then((res) => {
      // console.log(res);
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong. Please try again",
        });
        return res;
      }
    })
    .catch((e) => {
      console.error(e);
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong. Please try again",
      });
      return { success: false };
    });
};
const getMarketplaceRandom = (dispatch) => async (limit) => {
  dispatch({ type: "loading", payload: true });
  return await getData(`qorbaby/get-random?limit=${limit}`)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong getting the marketplace. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong getting the marketplace. Please try again",
      });
      return { success: false };
    });
};
const getMarketplace = (dispatch) => async (limit) => {
  dispatch({ type: "loading", payload: true });
  return await getData(`qorbaby/get/by/${limit}`)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong getting the marketplace. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong getting the marketplace. Please try again",
      });
      return { success: false };
    });
};
const getMarketPlaceStatus = (dispatch) => async () => {
  dispatch({ type: "loading", payload: true });
  return await getData("market/get/status")
    .then((res) => {
      if (res.success) {
        if (res.data && res.data.length > 0) {
          dispatch({ type: "marketplaceStatus", payload: res.data[0] });
        } else {
          dispatch({ type: "loading", payload: false });
        }
      } else {
        dispatch({ type: "loading", payload: false });
      }
      return res;
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      // Toast.set({
      //   show: true,
      //   type: "error",
      //   desc: "Something went wrong getting the marketplace. Please try again",
      // });
      return { success: false };
    });
};
const shoppingCart = (dispatch) => async (id) => {
  dispatch({ type: "loading", payload: true });
  return await postData("shopping-cart/add/NFT", { _id: id })
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong adding the NFT to the shopping cart. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong the NFT to the shopping cart. Please try again",
      });
      return { success: false };
    });
};
const removeshoppingCart = (dispatch) => async () => {
  dispatch({ type: "loading", payload: true });
  return await deleteData("shopping-cart/delete")
    .then((res) => {
      // console.log("delte", res);
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong deleting the shopping cart. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong deleting the shopping cart. Please try again",
      });
      return { success: false };
    });
};
const sendEmail = (dispatch) => async (message) => {
  dispatch({ type: "loading", payload: true });
  return await postData("email/send", { message })
    .then((res) => {
      // console.log(res);
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong sending the email. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong sending the email. Please try again",
      });
      return { success: false };
    });
};
const getBaby = (dispatch) => async (address) => {
  dispatch({ type: "loading", payload: true });
  return await getData(`qorbaby/get/NFT?Address=${address}`)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong getting the marketplace. Please try again",
        });
        return res;
      }
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong getting the marketplace. Please try again",
      });
      return { success: false };
    });
};
const getBabyNumber = (dispatch) => async () => {
  return await getData("number/get")
    .then((res) => {
      if (res.success) {
        dispatch({ type: "babiesNumber", payload: res.data[0].number });
      }
    })
    .catch((e) => {
      console.error(e);
    });
};
const searchNFT = (dispatch) => async (payload) => {
  dispatch({ type: "loading", payload: true });
  return await postData("qorbaby/search", payload)
    .then((res) => {
      if (!res.success) {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong getting. Please try again",
        });
      }
      dispatch({ type: "loading", payload: false });
      return res;
    })
    .catch((e) => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong getting. Please try again",
      });
      return { success: false };
    });
};
const getFilterOptions = (dispatch) => async () => {
  return await getData("qorbaby/get-filter-data")
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.success) {
        return res;
      } else {
        return { success: false };
      }
    })
    .catch(() => {
      return { success: false };
    });
};
const mintStatus = (dispatch) => async (code) => {
  dispatch({ type: "loading", payload: true });
  try {
    let res = await axios.post(`${immutableApi}nft/validate-mint-code`, {
      codeMint: code,
    });
    dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    Toast.set({
      show: true,
      type: "error",
      desc: error.response.data.message,
    });
    return { success: false };
  }
  // return await getData(`mint/${code}`)
  //   .then((res) => {
  //     dispatch({ type: "loading", payload: false });
  //     if (!res.success) {
  //       Toast.set({
  //         show: true,
  //         type: "error",
  //         desc: "Something went wrong. Please try again",
  //       });
  //     }
  //     return res;
  //   })
  //   .catch(() => {
  //     dispatch({ type: "loading", payload: false });
  //     Toast.set({
  //       show: true,
  //       type: "error",
  //       desc: "Something went wrong. Please try again",
  //     });
  //     return { success: false };
  //   });
};
const buyMint = (dispatch) => async (payload) => {
  dispatch({ type: "loading", payload: true });
  return await postData("shopping-cart/buy/mint", payload)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (!res.success) {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong buying mint. Please try again",
        });
      } else {
        Toast.set({
          show: true,
          type: "success",
          desc: "Well Done. Purchase completed successfully. Check your Qor Babies on your wallet.",
        });
      }
      return res;
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong buying mint. Please try again",
      });
      return { success: false };
    });
};

const createAffiliate = (dispatch) => async (payload) => {
  dispatch({ type: "loading", payload: true });
  return await postData("affiliate", payload)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (!res.success) {
        Toast.set({
          show: true,
          type: "error",
          desc: "Something went wrong create affiliate. Please try again",
        });
      }
      return res;
    })
    .catch(() => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: "Something went wrong create affiliate. Please try again",
      });
      return { success: false };
    });
};
const signUp = (dispatch) => async (email, password, username, dob) => {
  dispatch({ type: "loading", payload: true });
  const payload = {
    TitleId: TitleId,
    Password: password,
    Email: email,
    Username: username,
    CustomTags: { dob: dob },
  };
  return axios
    .post(`${PlayFabURL}RegisterPlayFabUser`, payload)
    .then((res) => {
      if (res.data.code !== 200) {
        dispatch({ type: "loading", payload: false });
        Toast.set({
          show: true,
          type: "error",
          desc: res.data.errorMessage,
        });
        return { success: false };
      } else {
        localStorage.setItem("session", JSON.stringify(res.data.data));
        dispatch({ type: "signup", payload: res.data.data });
        return { success: true, id: res.data.data.PlayFabId };
      }
    })
    .catch((e) => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: e.response.data.errorMessage,
      });
      return { success: false };
    });
};
const signIn = (dispatch) => async (authType, data) => {
  dispatch({ type: "loading", payload: true });
  if (authType === PlayFab) {
    const payload = {
      TitleId: TitleId,
      Password: data.password,
      Username: data.username,
    };
    return axios
      .post(`${PlayFabURL}LoginWithPlayFab`, payload)
      .then((res) => {
        if (res.data.code !== 200) {
          dispatch({ type: "loading", payload: false });
          Toast.set({
            show: true,
            type: "error",
            desc: res.data.errorMessage,
          });
          return { success: false };
        } else {
          res.data.data.authType = authType;
          localStorage.setItem("session", JSON.stringify(res.data.data));
          dispatch({ type: "login", payload: res.data.data });
          return { success: true, id: res.data.data.PlayFabId };
        }
      })
      .catch((e) => {
        dispatch({ type: "loading", payload: false });
        Toast.set({
          show: true,
          type: "error",
          desc: e.response.data.errorMessage,
        });
        return { success: false };
      });
  } else {
    data.authType = authType;
    dispatch({ type: "login", payload: data });
  }
};
const forgotPassword = (dispatch) => async (email) => {
  dispatch({ type: "loading", payload: true });
  const payload = {
    TitleId: TitleId,
    Email: email,
  };
  return axios
    .post(`${PlayFabURL}SendAccountRecoveryEmail`, payload)
    .then((res) => {
      dispatch({ type: "loading", payload: false });
      if (res.data.code !== 200) {
        Toast.set({
          show: true,
          type: "error",
          desc: res.data.errorMessage,
        });
        return { success: false };
      } else {
        return { success: true };
      }
    })
    .catch((e) => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: e.response.data.errorMessage,
      });
      return { success: false };
    });
};
const playfabUser = (dispatch) => async (user) => {
  dispatch({ type: "loading", payload: true });
  const payload = {
    PlayFabId: user,
    InfoRequestParameters: {
      GetUserData: true,
    },
  };
  axios.defaults.headers.common["X-SecretKey"] = secretKeyPlayFab;
  return axios
    .post(`${PlayFabURLServer}GetPlayerCombinedInfo`, payload)
    .then((res) => {
      if (res.data.code !== 200) {
        dispatch({ type: "loading", payload: false });
        Toast.set({
          show: true,
          type: "error",
          desc: res.data.errorMessage,
        });
        return { success: false };
      } else {
        dispatch({ type: "loginFab", payload: res.data.data });
        return { success: true, data: res.data.data };
      }
    })
    .catch((e) => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: e.response.data.errorMessage,
      });
      return { success: false };
    });
};
const updatePlayFabUser = (dispatch) => async (userId, data) => {
  dispatch({ type: "loading", payload: true });
  const payload = {
    PlayFabId: userId,
    Data: data,
  };
  axios.defaults.headers.common["X-SecretKey"] = secretKeyPlayFab;
  return axios
    .post(`${PlayFabURLServer}UpdateUserData`, payload)
    .then((res) => {
      if (res.data.code !== 200) {
        dispatch({ type: "loading", payload: false });
        Toast.set({
          show: true,
          type: "error",
          desc: res.data.errorMessage,
        });
        return { success: false };
      } else {
        return { success: true, data: res.data.data };
      }
    })
    .catch((e) => {
      dispatch({ type: "loading", payload: false });
      Toast.set({
        show: true,
        type: "error",
        desc: e.response.data.errorMessage,
      });
      return { success: false };
    });
};
const convertToUSD = (dispatch) => async (amount) => {
  dispatch({ type: "loading", payload: true });
  let res = await getData(`market/get/sol-to-usd/${amount}`);
  dispatch({ type: "loading", payload: false });
  if (res.success) {
    return { success: true, data: res.data };
  } else {
    Toast.set({
      show: true,
      type: "error",
      desc: res.data.message,
    });
    return { success: false };
  }
};
const transferMint = (dispatch) => async (address, codeMint) => {
  dispatch({ type: "loading", payload: true });
  try {
    let res = await axios.post(`${immutableApi}nft/transfer`, {
      addressToSend: address,
      codeMint,
    });
    dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data.message };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    Toast.set({
      show: true,
      type: "error",
      desc: error.response.data.message,
    });
    return { success: false };
  }
};
const AddCodeMint = (dispatch) => async (codeMint) => {
  dispatch({ type: "loading", payload: true });
  try {
    let res = await axios.post(`${immutableApi}nft/add-code-mint`, {
      codeMint,
    });
    dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data.message };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    Toast.set({
      show: true,
      type: "error",
      desc: "Something went wrong.",
    });
    return { success: false };
  }
};
const validateEggTrade = (dispatch) => async (tokensId) => {
  dispatch({ type: "loading", payload: true });
  try {
    let res = await axios.post(
      `${immutableApi}exchange/validate-egg-trade-jaguar`,
      {
        tokensId,
      }
    );
    // dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data.message };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    Toast.set({
      show: true,
      type: "error",
      desc: "The eggs are not valid to get a pet. You must have the Bronze, Silver, and Gold of the same pet.",
    });
    return { success: false };
  }
};
const getEggImage = (dispatch) => async () => {
  try {
    let res = await axios.get(`${immutableApi}details`);
    // dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    return { success: false };
  }
};
const transferJaguar = (dispatch) => async (tokensId, toWallet) => {
  dispatch({ type: "loading", payload: true });
  try {
    let res = await axios.post(`${immutableApi}exchange/transfer-jaguar`, {
      tokensId,
      toWallet,
    });
    dispatch({ type: "loading", payload: false });
    return { success: true, data: res.data.message };
  } catch (error) {
    dispatch({ type: "loading", payload: false });
    return { success: false };
  }
};
const localLogin = (dispatch) => async (payload) => {
  dispatch({ type: "login", payload: payload });
};
const userLogout = (dispatch) => async () => {
  localStorage.removeItem("session");
  dispatch({ type: "userLogout" });
};
export const { Provider, Context } = createDataContext(
  authWallet,
  {
    LoginWallet,
    logOut,
    getMarketplaceRandom,
    getBaby,
    getMarketplace,
    makeTransaction,
    buyNFT,
    getMarketPlaceStatus,
    shoppingCart,
    removeshoppingCart,
    sendEmail,
    checkWallet,
    getBabyNumber,
    searchNFT,
    getFilterOptions,
    mintStatus,
    buyMint,
    createAffiliate,
    signUp,
    signIn,
    playfabUser,
    updatePlayFabUser,
    forgotPassword,
    localLogin,
    userLogout,
    convertToUSD,
    immutableLogin,
    metamaskLogin,
    transferMint,
    AddCodeMint,
    validateEggTrade,
    transferJaguar,
    getEggImage,
    LoginWalletSphereone,
  },
  {
    loading: false,
    userWallet: null,
    account: null,
    loadingText: null,
    babiesNumber: 0,
    marketplaceStatus: null,
    user: null,
    userFab: null,
  }
);
