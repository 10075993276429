import React from "react";
import { useNavigate } from "react-router";
import { marketItems } from "../utils/Global";

const MarketItem = ({ item }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/single/${item.id}`);
  };

  return (
    <div className="col-12 col-md-4" style={{ marginBottom: 45, position: 'relative', paddingBottom: 60 }}>
      <div className="nft-card" style={{ boxShadow: "-2px 2px 14px 1px #51E180", borderRadius: "8px", height: "100%", marginBottom: 40 }}>
        <div style={{ padding: "30px" }}>
          <div
            className="nft-bg"
            style={{ backgroundImage: `url(${item.img})`, width: 400, maxWidth: "100%", margin: "0 auto" }}
            onClick={handleNavigate}
          ></div>
        </div>
        <div className="content" style={{ background: "transparent", position: 'relative' }}>
          <h4 className="fs-6 txt-green">
            {item.name}
          </h4>
          <p style={{ color: "white" }}>
            {item.desc}
          </p>
          <div
            className="txt-green mb-4 mt-4"
            style={{ height: 30, fontSize: 24 }}
          >
            ${item.price}.00
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10, position: "absolute", left: 0, width: "100%", paddingLeft: 20, paddingRight: 20, bottom: 60 }}>
        <button
          className="greenBtn"
          style={{ width: "100%", marginRight: "10px", height: 50 }}
          onClick={handleNavigate}
        >
          Mint Now
        </button>
        {item.moreUrl !== "" &&
          <a
            className="btnTransparent"
            style={{ width: "100%", marginLeft: "10px", height: 50 }}
            href={item.moreUrl}
            target="blank"
          >
            Learn More
          </a>
        }
      </div>
    </div>
  );
};

const Market = () => {

  return (
    <div className="darkbg market">
      <div className="section mt-5">
        <div className="container">
          <div className="row mt-5 align-items-stretch">
            <h1 style={{ color: '#51E180', fontSize: '40px', fontWeight: 'bold', marginBottom: '30px' }}>Discover, Trade, and Conquer: Your Journey Begins in the Qorbi World Market!</h1>
            {marketItems.filter(item => item.source === "Market").map(item => (
              <MarketItem key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;