import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../assets/lotties/Arrow_Bottom.json'

export const Banner = ({imgURL,videoURL='',title,subtitle,buttons,extraText=''}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
       <section className='banner-header'>
            <div className='bg' style={{backgroundImage:`url(${imgURL})`}}/>
            {
                videoURL!=='' ?
                    <video autoPlay muted loop id="bg-video">
                        <source src={videoURL} type="video/mp4"/>
                    </video>
                : null
            }
           <div className='content-bg'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            {extraText!=='' ? <h3 className='extra-text'>{extraText}</h3>:null}
                            <h1 className='banner-title'>{title}</h1>
                        </div>
                        <div className='col-12'>
                            <h5 className='banner-subtitle'>{subtitle}</h5>
                        </div>
                        <div className='col-12'>
                            {buttons}
                        </div>
                    </div>
                </div>
           </div>
           <div className='lottie'><Lottie options={defaultOptions} height={90} width={88} /></div>
       </section>
    );
};
