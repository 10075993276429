import { ethers } from 'ethers';
import { Web3Provider } from "@ethersproject/providers";
import detectEthereumProvider from '@metamask/detect-provider';
import nftAbi from './ABI/nft.json';

export const transferAsset = async (fromWalletAddress, toWalletAddress, tokenId) => {
    const provider = await detectEthereumProvider();
    if (!provider) {
        console.error('Ethereum provider not found');
        return;
    }
    try {
        await provider.request({ method: 'eth_requestAccounts' });
        const library = new Web3Provider(provider)
        const signer = library.getSigner();
        const contractAddress = '0x624ded48d23337787212F975157dC477e20b1334';
        const contract = new ethers.Contract(contractAddress, nftAbi.abi, signer);
        // Perform the transfer
        const tx = await contract.transferFrom(fromWalletAddress, toWalletAddress, tokenId, { gasPrice: 0, gasLimit: 60462 });
        // Wait for the transaction receipt
        const receipt = await library.waitForTransaction(tx.hash);
        return { receipt };
    } catch (error) {
        return { error };
    }
};