import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GameLogs = () => {
    const [logs, setLogs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [stat, setStat] = useState('');
    const [userId, setUserId] = useState('');
    const [errorBool, setErrorBool] = useState();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hour}:${minute}`;
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, stat, userId, errorBool]);

    const fetchData = async () => {
        let baseURL = `https://api.qorbiworld.com/log-sent-event/filter-log-sent-events?page=${currentPage}&thirdPartyService=on3`
        try {
            if (userId) {
                baseURL += `&username=${userId}`
            }
            if (stat) {
                baseURL += `&eventName=${stat}`
            }
            if (errorBool === 'true') {
                baseURL += `&hasError=${errorBool}`
            }
            const response = await axios.get(baseURL);
            setLogs(response.data.result);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlePageChange = (direction) => {
        setCurrentPage(prevPage => prevPage + direction);
    };

    const handleStatChange = (event) => {
        setStat(event.target.value);
    };

    const handleErrorChange = (event) => {
        setErrorBool(event.target.value);
    };

    const handleUserIdChange = (event) => {
        setUserId(event.target.value);
    };

    const handleSearch = () => {
        fetchData();
    };

    return (
        <div className="elixir-logs-container">
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Username"
                    value={userId}
                    onChange={handleUserIdChange}
                    className="search-input"
                />
                <select value={stat} onChange={handleStatChange} className="search-select">
                    <option value="">Select Stat</option>
                    <option value="One Tap Daily">One Tap Daily</option>
                    <option value="Win a match in One Tap">Win a match in One Tap</option>
                    <option value="QwQ Win a match in One Tap">QwQ Win a match in One Tap</option>
                    <option value="QwQ Get a headshot in One Tap">One</option>
                    <option value="Get a headshot in One Tap">Get a headshot in One Tap</option>
                    <option value="Assault Riffle Kill in One Tap">Assault Riffle Kill in One Tap</option>
                    <option value="QwQ  Assault Riffle Kill">QwQ  Assault Riffle Kill</option>
                    <option value="QwQ One Sniper Kill in One Tap">QwQ One Sniper Kill in One Tap</option>
                    <option value="One Sniper Kill in One Tap">One Sniper Kill in One Tap</option>
                    <option value="QwQ Get a shotgun kill">QwQ Get a shotgun kill</option>
                    <option value="Get a shotgun kill in One Tap">Get a shotgun kill in One Tap</option>
                    <option value="Play the One Tap game">Play the One Tap game</option>
                    <option value="Join the Whitelist for Qorbs">Join the Whitelist for Qorbs</option>
                </select>
                <select value={stat} onChange={handleErrorChange} className="search-select">
                    <option value="">Has Error</option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                </select>
                <button onClick={handleSearch} className="search-button">Search</button>
            </div>
            <table className="logs-table">
                <thead>
                    <tr>
                        <th>GameID</th>
                        <th>Username</th>
                        <th>Event Name</th>
                        <th>Error</th>
                        <th>Created At</th>
                    </tr>
                </thead>
                <tbody>
                    {logs && logs.map((log, index) => (
                        <tr key={index}>
                            <td>{log.gameId}</td>
                            <td>{log.username}</td>
                            <td>{log.eventName}</td>
                            <td>{log.hasError && (log?.error?.data?.errors || log?.error?.message)}</td>
                            <td>{formatDate(log.createdAt)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                {currentPage !== 1 && <button onClick={() => handlePageChange(-1)}>Previous</button>}
                {logs.length >= 30 && <button onClick={() => handlePageChange(1)}>Next</button>}
            </div>
        </div>
    );
};

export default GameLogs;
