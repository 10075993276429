/* eslint-disable no-lone-blocks */
import React, { useContext, useState } from "react";
import { MenuNoLogin } from "./MenuNoLogin";
import { Context } from "../../context/WalletContext";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Sphereone } from "../../utils/Global";
import { sphereoneSdk } from "../../utils/SphereOne";
import { useSphereOneProvider } from "../../utils/SphereOneHooks";

function NavBar() {
  const {
    state,
    logOut,
    userLogout,
    // LoginWalletSphereone,
  } = useContext(Context);
  const location = useLocation();
  const navigate = useNavigate();
  const { devicedUsed, setShowLogin } = useSphereOneProvider();
  const handleLoginClick = () => {
    setShowLogin(true);
  };
  const [submenu, setSubmenu] = useState(false);
  const toggleNavbar = () => {
    // menu mobile not working on ios. this code is to make it work
    const jQuery = window.jQuery
      if (jQuery('#navbarSupportedContent').hasClass('open')){
        jQuery('#navbarSupportedContent').addClass('close')
        jQuery('#navbarSupportedContent').removeClass('open')
      } else {
        jQuery('#navbarSupportedContent').addClass('open')
        jQuery('#navbarSupportedContent').removeClass('close')
      }
  };
  const logoutSphereOne = async () => {
    localStorage.removeItem("userInfo")
    if (localStorage.getItem("WALLET_ADDRESS")) {
      localStorage.removeItem("STARK_PUBLIC_KEY");
      localStorage.removeItem("WALLET_ADDRESS");
    }
    try {
      sphereoneSdk.logout();
    } catch (e) {
      console.error(e);
    }
    navigate("/");
  };
  const copyWallet = () => {
    setSubmenu(true);
    navigator.clipboard.writeText(state.userWallet.string);
    setTimeout(() => {
      setSubmenu(false);
    }, 3000);
  };
  return (
    <header
      id="header"
      style={
        location.pathname === "/signup" ||
          location.pathname === "/" ||
          location.pathname === "/download"
          ? { height: 75 }
          : null
      }
    >
      <nav
        data-aos="zoom-out"
        data-aos-delay={800}
        className="navbar navbar-expand-lg"
      >
        <div className="container-fluid container header" style={{width:"50%"}}>
          <Link className="navbar-brand" to="/">
            {location.pathname === "/signup" ||
              location.pathname === "/" ||
              location.pathname === "/download" ? (
              <img
                src="/assets/img/White_Full_Logo.png"
                alt="sticky brand-logo"
                width={100}
              />
            ) : (
              <img
                className="navbar-brand-sticky"
                src="/assets/img/logo.png"
                alt="sticky brand-logo"
              />
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
          >
            <i className="fa fa-bars"></i>
          </button>
        </div>
        <div className="container-fluid container">
          <div            
            id="navbarSupportedContent"
            className="close navbar-collapse"
          >
            <ul className="navbar-nav items mx-auto">
              <MenuNoLogin itemLocation={'navbar'}/>
            </ul>
            <div className="button-top-container">
              {state.user === null ? (
                <button className="btnTransparent" onClick={handleLoginClick}>
                  Login
                </button>
              ) : (
                <button
                  className="btnTransparent"
                  onClick={() => {
                    logOut();
                    userLogout();
                    navigate("/");
                    logoutSphereOne();
                  }}
                >
                  Logout
                </button>
              )}
              <div style={{ width: 10 }} />
              {devicedUsed === "computer" && (
                <button
                  id="address"
                  className="greenBtn cut-button"
                  onClick={() =>
                    state.userWallet ? copyWallet() : handleLoginClick()
                  }
                >
                  {state.userWallet
                    ? state.userWallet.string
                    : state?.user?.authType === Sphereone
                      ? "Connect External Wallet"
                      : "Connect Wallet"}
                </button>
              )}
              {submenu ? (
                <>
                  <div className="submenu">
                    <span style={{ color: 'green' }}>
                      Copied!
                    </span>
                  </div>
                  <div
                    className="overtouch"
                    onClick={() => setSubmenu(false)}
                  />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
