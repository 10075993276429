import axios from "axios";
import React, { useEffect, useState } from "react";
import Sort from "../assets/svg/Sort";

const DailyGamers = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    GetData();
  }, []);
  const GetData = async () => {
    axios
      .get("https://data.mongodb-api.com/app/data-vadbg/endpoint/readscores")
      .then((res) => {
        res.data.forEach((element) => {
          const rounds = [];
          for (let [key, value] of Object.entries(element)) {
            if (key.includes("Round")) {
              rounds.push(JSON.parse(String(value)));
            }
          }
          element["rounds"] = rounds;
        });
        setData(res.data.toReversed());
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const sortByDate = () => {
    setData(data.toReversed());
  };
  const displayRounds = (rounds) => {
    return rounds.map((item, index) => (
      <div className="row text-left" key={`round-${index}`}>
        <div className="text-white col-md-1 d-sm-flex justify-content-between">
          <div className="text-white d-none d-sm-block d-md-none">Round #:</div>
          Round {index}
        </div>
        <div className="text-white col-md-3 d-sm-flex justify-content-between">
          <div className="text-white d-none d-sm-block d-md-none">Red Team</div>
          <div>
            <b>Name:</b> {item.Teams[0].Members[0].Name} <br />
            <b>Kills: </b>
            {item.Teams[0].Members[0].Kills}
          </div>
        </div>
        <div className="text-white col-md-3 d-sm-flex justify-content-between">
          <div className="text-white d-none d-sm-block d-md-none">
            Blue Team
          </div>
          <div>
            <b>Name:</b> {item.Teams[1].Members[0].Name}
            <br />
            <b>Kills: </b> {item.Teams[1].Members[0].Kills}
          </div>
        </div>
        <div className="text-white col-md-3 d-sm-flex justify-content-between">
          <div className="text-white d-none d-sm-block d-md-none">
            Game Mode
          </div>
          <div>{item.GameMode}</div>
        </div>
        <div className="text-white col-md-2 d-sm-flex justify-content-between">
          <div className="text-white d-none d-sm-block d-md-none">Date</div>
          <div>{item.DateTime}</div>
        </div>
      </div>
    ));
  };
  return (
    <div className="darkbg">
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <h2 className="green-title">Daily Gamers</h2>
          </div>
        </div>
        <div
          className="row mt-5 p-2 text-left d-sm-none d-md-flex"
          style={{ background: "#29af6c5c" }}
        >
          <div className="text-white font-bold col-md-2">ID</div>
          <div className="col-md-9">
            <div className="row">
              <div className="text-white font-bold col-md-1">Round #</div>
              <div className="text-white font-bold col-md-3">Red Team</div>
              <div className="text-white font-bold col-md-3">Blue Team</div>
              <div className="text-white font-bold col-md-3">Mode</div>
              <div className="text-white font-bold flex items-center col-md-2">
                Date
                <Sort
                  onClick={() => sortByDate()}
                  style={{ cursor: "pointer", marginLeft: 5, fill: "white" }}
                />
              </div>
            </div>
          </div>
          <div className="text-white font-bold col-md-1">Winner</div>
        </div>
        {data.length > 0 ? (
          data.map((item, index) => (
            <div
              className="row border-b pb-3 pt-3 pr-2 pl-2"
              style={index % 2 === 0 ? { background: "#2dd6822b" } : null}
              key={item._id}
            >
              <div className="text-white text-break text-left col-md-2 d-sm-flex justify-content-between">
                <div className="text-white d-none d-sm-block d-md-none">
                  ID:
                </div>
                {item._id}
              </div>
              <div className="col-md-9">{displayRounds(item.rounds)}</div>
              <div className="text-white col-span-1 text-left col-md-1 d-sm-flex justify-content-between">
                <div className="text-white d-none d-sm-block d-md-none">
                  Winner:
                </div>
                {item.Winner}
              </div>
            </div>
          ))
        ) : (
          <div className="row">
            <div className="col-12">
              <h3 className="text-white text-center">No data to show</h3>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DailyGamers;
