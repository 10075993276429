import React from 'react';

const PinInstructionsOverlay = ({ closeInstructions }) => {

    return (
        <div className="darkbg pt-5 assets">
            <div
                style={{
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 75,
                    marginBottom: 75,
                }}
            >
                <div
                    style={{
                        background: 'black',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                        width: '75%',
                    }}
                >
                    <p style={{ fontSize: '36px', color: 'white' }}>
                        Important: You will need to enter a PIN for all transactions
                    </p>
                    <p style={{ fontSize: '24px', color: 'grey' }}>
                        Qorbi World uses the SphereOne wallet as the universal login and wallet system for all Qorbi World games and websites.
                        When you do a transaction such as purchase, transfer, or trade eggs for a pet you will be asked to enter a pin.
                        Please see example of the popup below. We use a pin to safe guard your SphereOne Wallet. To learn more about SphereOne please
                        {" "}
                        <a target='blank' href='https://www.sphereone.xyz' style={{ fontSize: '24px', color: 'grey' }}>
                            click here.
                        </a>
                    </p>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <p style={{ fontSize: '36px', color: 'white', marginRight: 25 }}>
                            Example Pop-up:
                        </p>
                        <img
                            src={require('../assets/img/ExamplePinPopup.png')}
                            alt='Example Popup'
                            style={{ width: '100%', maxWidth: '400px' }}
                        />
                    </div>
                    <button
                        onClick={closeInstructions}
                        style={{ width: "20%", marginRight: "10px", height: 50, marginTop: "25px" }}
                        className="greenBtn"
                    >
                        Enter Wallet
                    </button>

                </div>
            </div>
        </div>
    );
}

export default PinInstructionsOverlay;
