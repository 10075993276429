import React, { Component } from "react";
import { Banner } from "../components/Banner";
import { Context } from "../context/WalletContext";
import { NewCard } from "../components/NewCard";
import BundleHomePromotion from "../components/BundleHome";
import road from "../assets/img/road.png";
import arrow from "../assets/img/arrow.png";
import mana from "../assets/img/mana.png";
import immutable from "../assets/img/immutable.png";
import magistore from "../assets/img/magicstore.png"
import nftrade from "../assets/img/nftrade.png"
import sphereone from "../assets/img/sphereOne.png"
import t2latam from "../assets/img/t2latam.png"
import tokentrove from "../assets/img/tokentroveBanner.png"
import earnallieance from "../assets/img/earnallieance.png"
import owned from "../assets/img/owned.png";
import qor from "../assets/img/qor.png";
import elixir from "../assets/img/logoElixir.png";
import hyperplay from "../assets/img/HyperPlayCustomBadge.png";
import w3gg from "../assets/img/W3GG.png";
import portal from "../assets/img/portal.jpg";
import wombat from "../assets/img/wombat-logo-min.png";
import atomicHub from "../assets/img/AtomicHubLogo.png";
import kairon from "../assets/img/kairon.png";
import Forge from "../assets/svg/Forge";
import placeholderImage from "../assets/img/placeholder.png";
import newsOne from "../assets/img/news1.png";
import NFTrade from "../assets/img/NFTradeQorbi.png";
import OctopusImg from "../assets/img/Octopus.png";
import EasyLogin from "../assets/img/easylogin.png";
import EggToPet from "../assets/img/eggToPet.png";
import OwnedXOnetap from "../assets/img/OwnedXOnetap.png";
import Spielworks from "../assets/img/spielworks.jpg";
import HowToPlay from "../assets/img/HowToPlay.png"
import QorXPass from "../assets/img/QorXPassImage.png"
import imxImage from "../assets/img/imxTwoLayers.png"

const news = [
  {
    image: newsOne,
    title: "One Tap Beta Release: A New Era of Gaming is on the Horizon!",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Play One Tap",
    },
    timeAgo: "July 26, 2023",
    link: "https://playonetap.com/one-tap-beta-release",
  },
  {
    image: NFTrade,
    title: "NFTrade | Blog | One Tap: The Next Web3 Esports Sensation",
    content: "",
    game: {
      icon: placeholderImage,
      name: "NFTrade",
    },
    timeAgo: "November 22, 2023",
    link: "https://nftrade.com/blog/one-tap-the-next-web3-esports-sensation",
  },
  {
    image: OctopusImg,
    title: "Octopus Pet Drop",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 2, 2023",
    link: "https://qorbiworld.medium.com/octopus-pet-drop-28b1d9138e15",
  },
  {
    image: EggToPet,
    title: "Qorbi World Pet Egg System",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 4, 2023",
    link: "https://qorbiworld.medium.com/qorbi-world-pet-egg-system-2ad7a402ed75",
  },
  {
    image: EasyLogin,
    title: "One Tap Easy Login & Wallet Connection with SphereOne",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 4, 2023",
    link: "https://qorbiworld.medium.com/one-tap-easy-login-wallet-connection-with-sphereone-0bf4b24e6d35",
  },
  {
    image: OwnedXOnetap,
    title: "One Tap on Owned",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "December 5, 2023",
    link: "https://qorbiworld.medium.com/one-tap-on-owned-fea846950b88",
  },
  {
    image: Spielworks,
    title: "Spielworks and Qorbi World — a match made in gaming heaven",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "February 21, 2024",
    link: "https://medium.com/wombat/spielworks-and-qorbi-world-a-match-made-in-gaming-heaven-5053f9165245",
  },
  {
    image: imxImage,
    title: "Between 2 Layers",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Immutable X",
    },
    timeAgo: "February 24, 2024",
    link: "https://www.youtube.com/watch?v=7lV_uGh6ndE",
  },
  {
    image: HowToPlay,
    title: "How to Play One Tap",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "May 17, 2024",
    link: "https://qorbiworld.medium.com/how-to-play-one-tap-6d7605372c51",
  },
  {
    image: QorXPass,
    title: "QOR-X Pass Lifetime Pass",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "May 18, 2024",
    link: "https://qorbiworld.medium.com/qor-x-pass-lifetime-pass-084f6dc96436",
  },
  {
    image: 'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*OWzE9Rw3UVfhvnwFS9ixzQ.png',
    title: "Exciting Skin Bundles",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "July 24, 2024",
    link: "https://qorbiworld.medium.com/discover-the-exciting-skin-bundles-from-qorbi-world-4a34eb223968",
  },
  {
    image: 'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*IuKTfZPhjhORUpUkiQe9CQ.jpeg',
    title: "New Qorbi Pets",
    content: "",
    game: {
      icon: placeholderImage,
      name: "Medium",
    },
    timeAgo: "July 27, 2024",
    link: "https://qorbiworld.medium.com/new-qorbi-pets-only-available-in-bundles-04d159c6a47e",
  },
];
const invertedNews = news.reverse();
const team = [
  {
    name: "John Bardis",
    position: "Head of Gaming",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Arihan.png",
    url: "https://www.linkedin.com/in/johnbardis/",
  },
  {
    name: "Nadine Bardis",
    position: "Head of Esports",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Alazay.png",
    url: "https://www.linkedin.com/in/nadine-bardis-06730818a/",
  },
  {
    name: "Joe Tran",
    position: "Blockchain Advisor",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Azra.png",
    url: "https://www.linkedin.com/in/joetranmana/",
  },
  {
    name: "Diego Mazo",
    position: "Lead Game Developer",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Marcel.png",
    url: "https://www.linkedin.com/in/diego-mazo-974456167/",
  },
  {
    name: "Natalia Mendez",
    position: "Lead Game Designer",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Salia.png",
    url: "https://www.linkedin.com/in/natalia-mendez-672a99232/",
  },
  {
    name: "Vladimir Mendoza",
    position: "Graphics Designer",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Ryot.png",
    url: "https://www.linkedin.com/in/bladimirmendoza/",
  },
  {
    name: "Ruddy Fletes",
    position: "Community Manager",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Stewart.png",
    url: "https://www.linkedin.com/in/ruddy-fletes-ab15a4234/",
  },
  {
    name: "Osmany Jiron",
    position: "Blockchain Engineer",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Shah.png",
    url: "https://www.linkedin.com/in/osmanyjiron/",
  },
  {
    name: "Brian Dangla",
    position: "Software Engineer",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Avir.png",
    url: "https://www.linkedin.com/in/brian-dangla-8657401ab/",
  },
  {
    name: "Aldo Illanes",
    position: "Senior Concept Artist",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Charvik.png",
    url: "https://www.linkedin.com/in/aldo-delso-illanes/",
  },
  {
    name: "Steven Escobar",
    position: "3D Graphics Artist",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Kyllian.png",
    url: "https://www.linkedin.com/in/steven-escobar-3759a112a/",
  },
  {
    name: "Nathalia Oviedo",
    position: " 3D Animation",
    image: "https://d2ceeuvjz02d1t.cloudfront.net/images/Adream.png",
    url: "https://www.linkedin.com/in/nathalia-oviedo-lozano-379529157/",
  },
];
const nfts = [
  {
    name: "Founders Qorbs",
    position:
      " A 2000 peice collection dedicated to our most loyal Qorbi World supporters.",
    video: "https://d2ceeuvjz02d1t.cloudfront.net/collections/batch2vid.mp4",
    soldOut: true,
    link: "https://magiceden.io/marketplace/qorb_founders",
    btnText: "Magic Eden",
  },
  {
    name: "Qorbs",
    position:
      " The Qorbs are the key the entire ecosystem. Get exclusive access to the games, events, earning and voting.",
    video: "https://d2ceeuvjz02d1t.cloudfront.net/collections/batch2vid_comingsoon.mp4",
    soldOut: false,
    link: "https://qorbiworld.com/qorb-collection",
    btnText: "Join Waitlist",
  },
  {
    name: "Qorbi Pets",
    position:
      "Pets are the utility in the One Tap and Roam game. Take advatage of the abilities they give you and win more rounds and more Qorbi Coin",
    video: "https://d2ceeuvjz02d1t.cloudfront.net/petshowoff.mp4",
    soldOut: false,
    link: "https://tokentrove.com/collection/QorbiWorldOneTapPets",
    btnText: "Token Trove",
  },
  {
    name: "Skins/Weapons",
    position:
      "Our skin are always llimited quantity. Making every purchase a strong investment to your game play",
    video: "https://d2ceeuvjz02d1t.cloudfront.net/collections/weaponsshow.mp4",
    soldOut: false,
    link: "/market",
    btnText: "Buy Now",
  },
];
class Home extends Component {
  static contextType = Context;
  // async componentDidMount() {
  //   await this.context.getBabyNumber();
  // }
  render() {
    return (
      <div className="darkbg">
        <Banner
          //imgURL="https://imagedelivery.net/iqzXzl6cKZZq6VjsnAU2MA/e9280470-8872-4017-3c93-5cb56947af00/public"
          videoURL="https://d2ceeuvjz02d1t.cloudfront.net/webteaser.mp4"
          title={<>One Tap is now in Open Beta</>}
          //extraText={this.context.state.babiesNumber > 0 ? this.context.state.babiesNumber + ' Babies have found their Owners! PRESALE SOLD OUT' : ''}
          subtitle={
            <>
              "One Tap" is a high-octane first-person shooter game that delivers an immersive and competitive gaming experience.
              It's all about the thrill of the game, the strategy, and the skill. The unique twist? You get to choose an in-game
              asset with special abilities that can turn the tide of the battle.
            </>
          }
          buttons={
            <div className="text-center button-container-home">
              <div className="button-group-home">
                <button
                  className="greenBtn customBtn"
                  onClick={() => window.open("https://playonetap.com/download")}
                >
                  Download Now
                </button>
                <button
                  className="greenBtn customBtn"
                  onClick={() => window.open("https://quest.playmana.gg/quests")}
                >
                  Earn Rewards
                </button>
                <button
                  className="greenBtn customBtn"
                  onClick={() => window.open("https://discord.gg/FP9g49ur2r")}
                >
                  Join Community
                </button>
              </div>
            </div>
          }
        />
        {/* Bundles*/}
        <div className="section bundle-promotion-section">
          <div className="container">
            <BundleHomePromotion />
          </div>
        </div>

        {/* Bundles*/}
        <div className="section plan-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title text-center">Why Purchase a Qorb</h2>
                <p
                  style={{ fontSize: 18, marginBottom: 40 }}
                  className="text-center"
                >
                  A uniquely designed 3D animated character. This one of a kind
                  art was inspired by the innocence of our young people and
                  their love for esports. Every Qorb has attributes that
                  represent their special features. The Qorbs are created by the
                  Qorbi World an Estonia Company team of concept designers, 3D Artist, 3D Animators,
                  and game designers.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <iframe
                  title="col-12 col-lg-6"
                  src="https://iframe.cloudflarestream.com/29761506bb8ee924685951d53f5960e8?muted=true&poster=https%3A%2F%2Fcloudflarestream.com%2F29761506bb8ee924685951d53f5960e8%2Fthumbnails%2Fthumbnail.jpg%3Ftime=7s%3D%26height%3D800"
                  style={{ height: "100%", width: "100%", minHeight: 200 }}
                  allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                  allowFullScreen={true}
                ></iframe>
              </div>
              <div className="col-md-6">
                <div className="cont">
                  <div className="price">
                    <span className="symbol"></span>Qorbs Owner Benefits
                    <span className="cents"></span>
                  </div>
                  <ul className="desc">
                    <li>Exclusive Access to in-game items</li>
                    <li>
                      Only way to earn in Qorbi World is with a Qorbs Pro Pass
                    </li>
                    <li>Exclusive Games only for Pro Pass holders</li>
                    <li>Staking</li>
                    <li>Voting & Earning</li>
                    <li>Free in-game skins</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">Upcoming Digital Collectables</h2>
              </div>
            </div>
            <div className="row nft-card nft">
              {nfts.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div className="nft-bg">
                    <h4>{item.name}</h4>
                    <video autoPlay muted loop id="bg-video">
                      <source src={item.video} type="video/mp4" />
                    </video>

                    <div style={{ position: 'absolute', bottom: '10%', left: '50%', transform: 'translateX(-50%)', textAlign: 'center' }}>
                      {item.soldOut && <div className="sold-out-text" >Sold Out</div>}
                      <button className="greenBtn" style={{ backgroundColor: 'black', color: 'white', padding: '10px 20px', borderRadius: '5px', whiteSpace: "nowrap", }} onClick={() => window.open(item.link, '_blank')}>
                        {item.btnText}
                      </button>
                    </div>
                  </div>
                  <div className="name-container">
                    <span className="position">{item.position}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">LATEST NEWS</h2>
              </div>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", alignItems: "center", overflowY: 'auto', marginLeft: "8%" }}>
              {invertedNews.map((item, index) => (
                <NewCard
                  key={index}
                  image={item.image}
                  title={item.title}
                  content={item.content}
                  game={item.game}
                  link={item.link}
                  timeAgo={item.timeAgo}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="section roadmap bg-circle">
          <div className="container">
            <div className="row">
              <div className="col-12"></div>
              <h2 className="green-title text-center">Qorb Roadmap</h2>
              {/* desktop */}
              <div className="col-12 text-center hide-mobile">
                <img src={road} alt="road" className="road" />
                <div className="right text-left">
                  <div className="step step-6">
                    <h2>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                      <span className="number">6</span>2024 2nd Quarter
                    </h2>
                    <ul>
                      <li>Elder Council Governance Auditing Portal</li>
                      <li>Financial Reporting Portal</li>
                      <li>
                        Add Skins, Armor, and More Pets to NFT Marketplace
                      </li>
                      <li>Launch Pools</li>
                      <li>Launch One Tap Mobile</li>
                    </ul>
                  </div>
                  <div className="step step-4">
                    <h2>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                      <span className="number">4</span>2023 4th Quarter
                    </h2>
                    <ul>
                      <li>Launch Beta of One Tap</li>
                      <li>
                        Launch Elder Council for stage one of DAO building
                      </li>
                      <li>Launch Skin Sales</li>
                      <li>Open up wallets for purchasing goods with $Qorbi</li>
                    </ul>
                  </div>
                  <div className="step step-2">
                    <h2>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                      <span className="number">2</span>2023 2nd Quarter
                    </h2>
                    <ul>
                      <li>Launch Closed Beta</li>
                      <li>Open PlayOneTap.com</li>
                    </ul>
                  </div>
                </div>
                <div className="left text-right">
                  <div className="step step-5">
                    <h2>
                      2024 1st Quarter
                      <span className="number">5</span>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                    </h2>
                    <ul>
                      <li>Release full game</li>
                      <li>Launch Rental/Scholar System</li>
                      <li>Holder Request portal for Elder Council</li>
                      <li>Release Qorb Mint</li>
                    </ul>
                  </div>
                  <div className="step step-3">
                    <h2>
                      2023 3rd Quarter
                      <span className="number">3</span>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                    </h2>
                    <ul>
                      <li>Add in Game Marketplace</li>
                      <li>Add Skins and Pets to Marketplace</li>
                      <li>Leaderboards and Tournaments</li>
                    </ul>
                  </div>
                  <div className="step step-1">
                    <h2>
                      2023 1st Quarter <span className="number">1</span>
                      <img src={arrow} alt="arrow" style={{ width: 50 }} />
                    </h2>
                    <ul>
                      <li>Launch Alpha</li>
                      <li>Pet Egg Mints</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* mobile */}
              <div className="col-12 text-center show-mobile">
                <div className="step">
                  <h2>
                    <span className="number">6</span>2024 2nd Quarter
                  </h2>
                  <ul>
                    <li>Elder Council Governance Auditing Portal</li>
                    <li>Financial Reporting Portal</li>
                    <li>Add Skins, Armor, and More Pets to NFT Marketplace</li>
                    <li>Launch Pools</li>
                    <li>Launch One Tap Mobile</li>
                  </ul>
                </div>
                <div className="step">
                  <h2>
                    <span className="number">5</span> 2024 1st Quarter
                  </h2>
                  <ul>
                    <li>Release full game</li>
                    <li>Launch Rental/Scholar System</li>
                    <li>Holder Request portal for Elder Council</li>
                    <li>Release Qorb Mint</li>
                  </ul>
                </div>
                <div className="step">
                  <h2>
                    <span className="number">4</span>2023 4th Quarter
                  </h2>
                  <ul>
                    <li>Launch Beta of One Tap</li>
                    <li>Launch Elder Council for stage one of DAO building</li>
                    <li>Launch Skin Sales</li>
                    <li>Open up wallets for purchasing goods with $Qorbi</li>
                  </ul>
                </div>
                <div className="step">
                  <h2>
                    <span className="number">3</span> 2023 3rd Quarter
                  </h2>
                  <ul>
                    <li>Add in Game Marketplace</li>
                    <li>Add Skins and Pets to Marketplace</li>
                    <li>Leaderboards and Tournaments</li>
                  </ul>
                </div>
                <div className="step">
                  <h2>
                    <span className="number">2</span>2023 2nd Quarter
                  </h2>
                  <ul>
                    <li>Launch Closed Beta</li>
                    <li>Open PlayOneTap.com</li>
                  </ul>
                </div>
                <div className="step">
                  <h2>
                    <span className="number">1</span>2023 1st Quarter
                  </h2>
                  <ul>
                    <li>Launch Alpha</li>
                    <li>Pet Egg Mints</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="container logos-partners">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">Partners</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={tokentrove} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={mana} alt="mana" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={immutable} alt="immutablex" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={qor} alt="qorgaming" />
              </div>
              {/* <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={me} alt="magic" />
              </div> */}
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={elixir} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={hyperplay} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={w3gg} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={portal} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={atomicHub} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={wombat} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={kairon} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <Forge></Forge>
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={magistore} alt="magistore" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={nftrade} alt="magic" />
              </div>
              {/* <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={rarible} alt="magic" />
              </div> */}
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={sphereone} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={t2latam} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={earnallieance} alt="magic" />
              </div>
              <div className="col-md-3 mb-5 align-items-center d-flex justify-content-center">
                <img src={owned} alt="magic" />
              </div>
            </div>
          </div>
        </div>
        <div className="section team">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">Meet The Team</h2>
              </div>
            </div>
            <div className="row nft-card">
              {team.map((item, index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                  <div
                    className="nft-bg"
                    style={{ backgroundImage: `url(${item.image})` }}
                  />
                  <div className="name-container">
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <h4>{item.name}</h4>
                        <i className="fa fa-linkedin-square" />
                      </div>
                      <span className="position">{item.position}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
