import React, { useContext } from "react";
import IMXLogo from "../assets/svg/ImmutableLogo";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WombatLogo from "../assets/img/wombat-logo-min.png";
import Close from "../assets/svg/Close";
import axios from "axios";
import { Ethereum, ImmutableX, Toast } from "../utils/Global";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import { Context } from "../context/WalletContext";
import { sphereoneSdk } from "../utils/SphereOne";

const SignIn = () => {
  const storedItem = localStorage.getItem('unauthorizedPath');
  const { immutableLogin, LoginWallet, metamaskLogin, localLogin } = useContext(Context);
  const { showLogin, setShowLogin, setRegisterWallet, setRegisterUser } = useSphereOneProvider();

  const handleCloseClick = () => {
    setShowLogin(false);
  };
  const isIMX = async (address) => {
    try {
      const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
      if (immutableResponse.data) {
        return true; // Here we check if the user exists
      }
    } catch (immutableError) {
      return false; // Here, the user needs to register into IMX
    }
  };
  const validateWallet = async (address) => {
    const isImx = await isIMX(address)
    if (isImx) {
      try {//Validate is the user with that wallet already exists, else create it
        const userResponse = await axios.get(`https://api.qorbiworld.com/sphere-one/user/passport/${address}`);
        console.log(userResponse);
      } catch (sphereOneError) {
        setRegisterUser(true);
        console.log("I am in the login");
      }
    } else {
      setRegisterWallet({ show: true, wallet: address })
    }
  };

  const connect_Immutable = async () => {
    try {
      if ("ethereum" in window) {
        const userAddress = await immutableLogin();
        if (userAddress.data) {
          const userInfo = {
            authType: ImmutableX,
            data: userAddress.data,
            isWombat: undefined
          };
          const userInfoString = JSON.stringify(userInfo);
          localStorage.setItem('userInfo', userInfoString);
          validateWallet(userInfo.data)
          if (storedItem) {
            localStorage.removeItem('unauthorizedPath');
            window.location.href = storedItem;
          }
          localLogin(userInfo);
          handleCloseClick()
        }
      } else {
        LoginWallet();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connect_eth_provider = async () => {
    try {
      if ("ethereum" in window) {
        const userAddress = await metamaskLogin();
        if (userAddress.data) {
          const userInfo = {
            authType: Ethereum,
            data: userAddress.data,
            isWombat: window.ethereum.isWombat
          };
          const userInfoString = JSON.stringify(userInfo);
          localStorage.setItem('userInfo', userInfoString);
          validateWallet(userInfo.data)
          localLogin(userInfo);
          handleCloseClick()
          if (storedItem) {
            localStorage.removeItem('unauthorizedPath');
            window.location.href = storedItem;
          }
        }
      } else {
        Toast.set({
          show: true,
          desc: (
            <p>
              Metamask is not installed. <br />
              <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                Metamask
              </a>
            </p>
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const login_SphereOne = async () => {
    try {
      await sphereoneSdk.login();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {showLogin &&
        <div className="signin-overlay">
          <div className="signin-box">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <h1>SIGN IN</h1>
              <div style={{ cursor: 'pointer' }} onClick={handleCloseClick}>
                <Close />
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div className="welcome">
                <p style={{ marginRight: 3 }}>Welcome to</p>
                <p style={{ color: "green" }}>Qorbi World</p>
              </div>
              <div></div>
            </div>
            <div className="signin-buttons">
              <button onClick={login_SphereOne} style={{ color: "black" }}>
                Login or Register Now
              </button>
            </div>
            <p className="welcome">Or sign in with your wallet</p>
            <div className="signin-buttons">
              <button onClick={connect_eth_provider} style={{ backgroundColor: "#eb8022" }}>
                <MetamaskLogo /> Connect with Metamask
              </button>
              <button onClick={connect_eth_provider} style={{ backgroundColor: "#FF4F24" }}>
                <img src={WombatLogo} alt="Wombat Logo" width={25} height={25} />Connect with Wombat
              </button>
              <button onClick={connect_Immutable} style={{ backgroundColor: "#14b3cb" }}>
                <IMXLogo /> Connect with ImmutableX
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default SignIn;
