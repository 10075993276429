import React from 'react';
import Failed from '../assets/svg/Failed'

const SuccessPage = () => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
    };

    const cardStyle = {
        maxWidth: '600px',
        backgroundColor: '#1A1919',
        color: '#000000',
        border: '3px #fff',
        borderRadius: '12%',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
    };

    const titleStyle = {
        fontSize: '36px',
        fontWeight: 'bold',
        marginBottom: '10px',
        color: '#fff',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '8px',
        marginTop: '20px',
    };

    const textStyle = {
        color: '#636363',
        marginTop: '20px',
    };

    return (
        <div style={containerStyle}>
            <div style={cardStyle}>
                <Failed></Failed>
                <img
                    src="https://d2ceeuvjz02d1t.cloudfront.net/collections/loot_box_icon.png"
                    alt="Transaction Successful"
                    style={imageStyle}
                />
                <h2 style={titleStyle}>Transaction Failed</h2>
                <p style={textStyle}>
                    <strong style={{ color: "#fff" }}>Name:</strong> One Tap Loot Box
                </p>
                <p style={textStyle}>
                    Your transaction failed. Please{" "}
                    <a href="https://discord.com/channels/871159116012326952/931999663937187890" target="_blank" rel="noopener noreferrer">
                        contact us
                    </a>{" "}
                    to find out what went wrong.
                </p>
            </div>
        </div>
    );
};

export default SuccessPage;