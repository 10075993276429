/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Context } from "../context/WalletContext";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import WalletSelectionOverlay from "../components/WalletSelectionOverlay";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";
import { marketItems } from "../utils/Global";

const SingleMarket = () => {
  const { id } = useParams();
  const [itemToShow, setItemToShow] = useState(null);
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const { sphereOneUser } = useSphereOneProvider();
  const { state } = useContext(Context);

  const readCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name && id !== "21") {
        return cookieValue;
      }
    }

    return null; // Cookie not found
  };

  const buyItem = () => {
    const username = "QorbiUser"
    let referralID = readCookie("rewardful.referral")?.split("%22")[3]
    if (referralID === undefined) {
      referralID = ""
    }
    try {
      window.open(
        itemToShow.stripe + `${state.userWallet.string}${username}${referralID}`,
        "_blank",
        "data-rewardful"
      )
    } catch (error) {
      console.log("error: ", error);
      localStorage.setItem('unauthorizedPath', `/single/${id}`);
      setShowConnectMessage(true)
    }
  }

  useEffect(() => {
    localStorage.removeItem('unauthorizedPath');
    const fetchItemInfo = () => {
      try {
        // if (marketItems[id] !== undefined || marketItems[id]?.source !== "Private Market") {
        if (marketItems[id] !== undefined) {
          setItemToShow({
            name: marketItems[id].name,
            image: marketItems[id].img,
            amount: marketItems[id].price,
            stripe: marketItems[id].url,
            coinbase: marketItems[id].coinbase,
          });
        } else {
          setItemToShow({
            name: marketItems[0].name,
            image: marketItems[0].img,
            amount: marketItems[0].price,
            stripe: marketItems[0].url,
            coinbase: marketItems[0].coinbase,
          });
        }

      } catch (error) {
        console.error(error);
      }
    };

    fetchItemInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="darkgreen-bg">
      {sphereOneUser !== null && id !== "0" && <WalletSelectionOverlay></WalletSelectionOverlay>}
      {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
      <div className="section">
        <div className="container">
          <div className="row single-baby">
            <div className="col-12 col-md-7">
              <div className="row media-container">
                <div className="col-md-12 col-lg-10">
                  <img src={itemToShow?.image} style={{ width: "100%" }} alt="Minting" />
                  <h4 className="attribute-title">
                    As soon as you finish minting contact an admin on discord
                    to get the Qor Owner status. To see your {itemToShow?.name} click on{" "}
                    <a
                      href="/my-assets"
                      rel="noreferrer"
                      target="_blank"
                      style={{ color: "#51e180" }}
                    >
                      My Assets
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5">
              <h2 className="baby-name">{itemToShow?.name}</h2>
              <div className="baby-price">{itemToShow?.amount}$</div>
              <div>
                <button className="greenBtn" onClick={() => buyItem()}>
                  Mint Using Credit Card
                </button>
              </div>
              {itemToShow?.coinbase &&
                <div>
                  <div className="tab-container">
                    <div className="tab-body">
                      <h2 className="baby-name" style={{ fontSize: "22px", marginTop: "30px" }}>Or...</h2>
                    </div>
                  </div>
                  <button className="greenBtn" onClick={() => window.open(itemToShow.coinbase)}>
                    Mint Using Crypto
                  </button>
                  <p style={{ fontSize: 16, color: "red" }}>Important: If you use this method, the wallet you use to pay from is where we will send the {itemToShow?.name}. If you are not sure about the wallet, please open a support ticket in discord.</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleMarket;
