import React from 'react';
import { Link } from 'react-router-dom';

export const Button = ({type="button",label,onClick, transparent=false,link}) => {
    return (
        <>
            {
                type==='button' ?
                    <button onClick={onClick} className={transparent ? 'btnTransparent' : 'greenBtn'}>{label}</button>
                :
                    <Link to={link} className={transparent ? 'btnTransparent' : 'greenBtn'}><span>{label}</span></Link>
            }
        </>
    );
};
