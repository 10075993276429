import React, { useContext, useEffect } from "react";
import image from "../assets/img/ConnectWallet.svg";
import { Context } from "../context/WalletContext";
import { PlayFab, Toast } from "../utils/Global";

const PlayOneTap = () => {
  const {
    state,
    checkWallet,
    immutableLogin,
    updatePlayFabUser,
    LoginWallet,
    playfabUser,
    // LoginWalletSphereone,
  } = useContext(Context);
  // const [username, setUsername] = useState();
  // useEffect(() => {
  //   if (state.user.authType === PlayFab) {
  //     setUsername(JSON.parse(localStorage.getItem("session")).Username);
  //   } else {
  //     setUsername(state.user.name);
  //   }
  // }, []);
  const getUserDetails = async (id) => {
    await playfabUser(id);
  };
  const registerWallet = async () => {
    let res = await checkWallet();
    if (res) {
      let res = await immutableLogin();
      if (res.success) {
        let res2 = await updatePlayFabUser(state.userFab.PlayFabId, {
          walletid: res.data,
        });
        if (res2.success) {
          getUserDetails(state.userFab.PlayFabId);
          Toast.set({
            show: true,
            type: "success",
            desc: "Wallet address saved successfully.",
          });
        }
      }
    } else {
      LoginWallet();
    }
  };
  // const ChangeWallet = () => {
  //   PopUp.set({
  //     show: true,
  //     title: "Wallet List",
  //     content: (
  //       <div className="wallet-options">
  //         {state.userWallet.wallets.map((item, index) => (
  //           <button
  //             key={index}
  //             style={{ display: "block", lineHeight: 1, marginBottom: 10 }}
  //             className={`${state.userWallet.string === item.address
  //               ? "greenBtn"
  //               : "btnTransparent"
  //               } btnOpt`}
  //             onClick={async () => {
  //               LoginWalletSphereone(
  //                 state.userWallet.wallets[index],
  //                 state.userWallet.wallets
  //               );
  //               PopUp.set({ show: false, title: "", content: null });
  //             }}
  //           >
  //             {item.type}
  //             <br />
  //             <small style={{ fontSize: 10 }}>{item.address}</small>
  //           </button>
  //         ))}
  //       </div>
  //     ),
  //   });
  // };
  useEffect(() => {
    localStorage.removeItem('unauthorizedPath');
  })
  return (
    <div className="darkbg section">
      <div className="container pt-5">
        {/* <div className="text-right">
          <h2 className="fs-5 mb-4 mt-4">
            <span className="txt-green">Select</span> Here to Mint:{" "}
            <a
              href={`https://form.jotform.com/232487510295156?gamerid=${username}`}
              target="_blank"
              className="greenBtn"
              style={{ fontSize: 12, width: 80, height: 30 }}
            >
              Mint Egg
            </a>
          </h2>
        </div> */}
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="box-rounded">
              <h2 className="text-center">Join The Fun</h2>
              <p>
                We are looking for hereos. Peopele who are ready to enter our
                arena and
                <br /> fight for honor and glory If that is you, then you have
                found the right game.
                <br /> This is a limite access and it will be closed to be
                public soon, join now. Visit <br /> the marketplace for in -game
                skins and pets.
              </p>
              <div className="d-flex justify-content-between mt-4">
                <a
                  href="https://whitepaper.qorbiworld.com/qorbi-world/"
                  target="_blank"
                  className="btnTransparent" rel="noreferrer"
                >
                  Learn More
                </a>
                <a
                  href="https://download.playonetap.com/"
                  target="_blank"
                  className="greenBtn" rel="noreferrer"
                >
                  Download
                </a>
              </div>
            </div>
          </div>
          {state?.user?.authType === PlayFab ? (
            <div className="col-md-6 mb-4">
              <div className="box-rounded">
                <div className="text-center mb-2">
                  <img src={image} height={40} alt="img-connect" />
                </div>

                <h2 className="text-center">Connect Gamer Tag to MetaMask</h2>
                {state.userFab !== null &&
                  state.userFab.InfoResultPayload.UserData.walletid &&
                  state.userFab.InfoResultPayload.UserData.walletid.Value !==
                  null ? (
                  <p>
                    {`This account is currently register to address:`} <br />
                    {state.userFab.InfoResultPayload.UserData.walletid.Value}
                  </p>
                ) : (
                  <p>
                    This will connect your Pets to your Gamer Tag. Once you
                    connect here your you have to restart your client to see
                    your pets in the game.
                  </p>
                )}
                <button
                  className="greenBtn mt-4"
                  style={{ width: "100%" }}
                  onClick={() => registerWallet()}
                >
                  {state.userFab !== null &&
                    state.userFab.InfoResultPayload.UserData.walletid &&
                    state.userFab.InfoResultPayload.UserData.walletid.Value !==
                    null
                    ? "Update Wallet"
                    : "Register Wallet"}
                </button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="box-rounded">
              <h2>
                <span className="txt-green">Minimum</span> system requirements:
              </h2>
              <ul>
                <li>
                  <span className="txt-green">CPU:</span> Intel Core i3 2.4GHz
                </li>
                <li>
                  <span className="txt-green">RAM:</span> 4 GB RAM
                </li>
                <li>
                  <span className="txt-green">GPU:</span> Intel HD Graphics 4000
                </li>
                <li>
                  <span className="txt-green">OS:</span> 64-bit Windows 10
                </li>
                <li>
                  <span className="txt-green">STO:</span> 10 GB available space
                </li>
                <li>
                  <span className="txt-green">DX:</span> DirectX 11
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="box-rounded">
              <h2>
                <span className="txt-green">Recommended</span> system
                requirements:
              </h2>
              <ul>
                <li>
                  <span className="txt-green">CPU:</span> Intel Core i5 2.8GHz
                  or higher
                </li>
                <li>
                  <span className="txt-green">RAM:</span> 16 GB RAM
                </li>
                <li>
                  <span className="txt-green">GPU:</span> Nvidia GeForce GTX
                  1080
                </li>
                <li>
                  <span className="txt-green">OS:</span> 64-bit Windows 10
                </li>
                <li>
                  <span className="txt-green">STO:</span> 10 GB available space
                </li>
                <li>
                  <span className="txt-green">DX:</span> DirectX 11
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayOneTap;
