import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import { Context } from "../context/WalletContext";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import { useNavigate } from 'react-router-dom';

const QorX = () => {
    const { state } = useContext(Context);
    const [qorXTransactions, setQorXTransactions] = useState(null);
    const { sphereOneWallets, devicedUsed } = useSphereOneProvider();
    const ownerAddress = (sphereOneWallets && sphereOneWallets.find((wallet) => !wallet.isImported)) || state.user.data;
    // const ownerAddress = (sphereOneWallets.find((wallet) => !wallet.isImported) || state.user.data);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`https://api.qorbiworld.com/stake/get-only-qor-x-active/${ownerAddress.address || ownerAddress}`);
            if (data.qorXPass !== null) {
                const response = await axios.get(`https://api.qorbiworld.com/stake/qor-x-passes-details/${data.qorXPass.qorXNFTID}`);
                setQorXTransactions(response.data);
            } else {
                navigate('/my-assets');
            }
        } catch (error) {
            console.error('Error fetching QorX data:', error);
        }
    };

    const formatDate = (fullDate) => {
        const dateObject = new Date(fullDate);
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');
        const year = dateObject.getFullYear().toString().substring(2);
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        if (ownerAddress && qorXTransactions === null) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qorXTransactions]);

    const renderMobileView = () => {
        return (
            <div style={{ padding: '20px', marginTop: 50, textAlign: 'center', fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                {qorXTransactions && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src={qorXTransactions?.stakeAsset?.asset?.image_url}
                                alt="QorX"
                                style={{
                                    width: 'auto',
                                    height: '40vh',
                                    border: '5px solid #2DD881',
                                    borderRadius: '15px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ color: 'white', fontWeight: 'bold' }}>

                                <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                    {`${qorXTransactions?.stakeAsset?.asset?.name} #${qorXTransactions?.stakeAsset?.asset?.metadata?.serial_number}`}
                                </div>
                                <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                    Current Multiplier: X {qorXTransactions?.multiplierData?.multiplier}
                                </div>
                                <br></br>
                                <div style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                    fontSize: '34px',
                                    marginBottom: '10px',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    backgroundColor: '#1A1919',
                                    borderRadius: '30px',
                                    padding: '15px'
                                }}>
                                    <img
                                        src={qorbi_coin}
                                        alt="Qorbi_Coin"
                                        style={{
                                            width: 'auto',
                                            height: '50px',
                                        }}
                                    />
                                    ‎ Qorbi Earned: {Math.floor(qorXTransactions?.stakeAsset?.stake?.earned * 10000) / 10000}
                                </div>
                                <button
                                    style={{
                                        background: "#DD8A06",
                                        color: "white",
                                        borderRadius: "20px",
                                        height: "55px",
                                        display: "inline-flex",
                                        width: "200px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    onClick={() => window.open('https://qorbi.getrewardful.com', '_blank')}
                                >
                                    Referral Program
                                </button>
                            </div>
                        </div>
                        {qorXTransactions?.stakeAsset?.historiesEarn?.length > 0 && (
                            <div style={{ color: 'white', marginTop: 25 }}>
                                <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: '40px' }}>History of Staking</h3>
                                <div style={{ width: '100%', marginTop: '10px', textAlign: 'start' }}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                    }}>
                                        <div style={{ flex: 1 }}>Date</div>
                                        <div style={{ flex: 1 }}>Daily Qorbi</div>
                                        <div style={{ flex: 1 }}>Multiplier</div>
                                        <div style={{ flex: 1 }}>Qorbi</div>
                                    </div>
                                    {qorXTransactions?.stakeAsset?.historiesEarn.slice().reverse().map((row) => (
                                        <div key={row.date} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                            <div style={{ flex: 1 }}>{formatDate(row.date)}</div>
                                            <div style={{ flex: 1 }}>{row.dayliQorbi}</div>
                                            <div style={{ flex: 1 }}>{row.multiplier}</div>
                                            <div style={{ flex: 1, color: '#37EB7B' }}>{row.qorbi}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {qorXTransactions?.multiplierData?.itemsMultipliers?.length > 0 && (
                            <div style={{ marginTop: '50px', marginRight: '20px', color: "white" }}>
                                <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>Multiplier Items</h3>
                                <div style={{ width: '100%', marginTop: '10px', textAlign: 'start' }}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                    }}>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ flex: 4 }}>Item</div>
                                        <div style={{ flex: 2 }}>Multiplier</div>
                                    </div>
                                    {qorXTransactions?.multiplierData?.itemsMultipliers.map((row) => (
                                        <div key={row.id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                            <div style={{ flex: 1 }}></div>
                                            <div style={{ flex: 4 }}>{row.name}</div>
                                            <div style={{ flex: 2 }}>
                                                {`x ${row.multiplier}`}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        );
    };

    const renderComputerView = () => {
        return (
            <div style={{ display: 'flex', marginTop: 75, marginLeft: 75, fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                <div style={{ flex: '2', padding: '20px' }}>
                    {qorXTransactions && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src={qorXTransactions?.stakeAsset?.asset?.image_url}
                                    alt="QorX"
                                    style={{
                                        width: 'auto',
                                        height: '40vh',
                                        border: '5px solid #2DD881',
                                        borderRadius: '15px',
                                        marginRight: '20px',
                                    }}
                                />
                                <div>
                                    <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                        {`${qorXTransactions?.stakeAsset?.asset?.name} #${qorXTransactions?.stakeAsset?.asset?.metadata?.serial_number}`}
                                    </div>

                                    <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                        Current Multiplier: X {qorXTransactions?.multiplierData?.multiplier}
                                    </div>
                                    <br></br>
                                    <div style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: '34px',
                                        marginBottom: '50px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        backgroundColor: '#1A1919',
                                        borderRadius: '30px',
                                        padding: '15px'
                                    }}>
                                        <img
                                            src={qorbi_coin}
                                            alt="Qorbi_Coin"
                                            style={{
                                                width: 'auto',
                                                height: '50px',
                                            }}
                                        />
                                        ‎ Qorbi Earned: {Math.floor(qorXTransactions?.stakeAsset?.stake?.earned * 10000) / 10000}
                                    </div>
                                    <button
                                        style={{
                                            background: "#DD8A06",
                                            color: "white",
                                            borderRadius: "20px",
                                            height: "55px",
                                            display: "inline-flex",
                                            width: "200px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            marginTop: 10,
                                        }}
                                        onClick={() => window.open('https://qorbi.getrewardful.com', '_blank')}
                                    >
                                        Referral Program
                                    </button>
                                </div>
                            </div>
                            {qorXTransactions?.stakeAsset?.historiesEarn?.length > 0 && (
                                <div style={{ color: 'white', marginTop: 25 }}>
                                    <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Staking</h3>
                                    <div style={{ width: '100%', marginTop: '10px' }}>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                        }}>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>Date</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>Daily Qorbi</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>Multiplier</div>
                                            {/* <div style={{ flex: 1, paddingLeft: '100px' }}>Sum</div> */}
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>Qorbi</div>
                                        </div>
                                        {qorXTransactions?.stakeAsset?.historiesEarn.slice().reverse().map((row) => (
                                            <div key={row.date} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                                <div style={{ flex: 1, paddingLeft: '100px' }}>{formatDate(row.date)}</div>
                                                <div style={{ flex: 1, paddingLeft: '100px' }}>{row.dayliQorbi}</div>
                                                <div style={{ flex: 1, paddingLeft: '100px' }}>{row.multiplier}</div>
                                                <div style={{ flex: 1, paddingLeft: '100px', color: '#37EB7B' }}>{row.qorbi}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                {
                    // (qorXTransactions?.multiplierData?.itemsSum?.length > 0 || qorXTransactions?.multiplierData?.itemsMultipliers?.length > 0) && (
                    (qorXTransactions?.multiplierData?.itemsMultipliers?.length > 0) && (
                        <div style={{ flex: '1', padding: '20px', marginTop: '25px', marginRight: '75px' }}>
                            <div style={{ color: 'white', marginTop: 25 }}>
                                <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>Multiplier Items</h3>
                                <div style={{ width: '100%', margin: '0 auto', marginTop: '10px', overflowX: 'hidden' }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        background: "#1A1919",
                                        border: "1px solid #FFFFFF0D",
                                        borderRadius: "25px",
                                        opacity: "1",
                                        padding: "10px"
                                    }}>
                                        <div style={{ flex: 4, paddingLeft: "30px" }}>Item</div>
                                        <div style={{ flex: 2 }}>Multiplier</div>
                                    </div>
                                    {/* {mergeAndSortItems(qorXTransactions?.multiplierData?.itemsSum, qorXTransactions?.multiplierData?.itemsMultipliers).map((row) => ( */}
                                    {qorXTransactions?.multiplierData?.itemsMultipliers.map((row) => (
                                        <div key={row.id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                            <div style={{ flex: 4, paddingLeft: "30px" }}>{row.name}</div>
                                            <div style={{ flex: 2 }}>{`x ${row.multiplier}`}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )
                }
            </div >
        );
    };

    return devicedUsed === 'mobile' ? renderMobileView() : renderComputerView();
};

export default QorX;