import axios from 'axios';

const transformSaakuruAsset = (saakuruAsset, user) => {
    return {
        token_address: '0x624ded48d23337787212f975157dc477e20b1334',
        token_id: saakuruAsset.tokenId.toString(),
        id: null,
        user: user,
        status: 'saakuru',
        uri: saakuruAsset.tokenUri,
        name: saakuruAsset.metadata.name,
        description: saakuruAsset.metadata.description,
        image_url: saakuruAsset.metadata.image,
        collection: {
            name: 'One Tap Weapon Skins Color Series',
            icon_url: 'https://d2ceeuvjz02d1t.cloudfront.net/collections/colorskinsicon.png'
        },
        created_at: null,
        updated_at: null,
        metadata: saakuruAsset.metadata.attributes.reduce((acc, attribute) => {
            acc[attribute.trait_type] = attribute.value;
            return acc;
        }, {})
    };
};

export const getAssetsFromCollections = async (user, updateAssets) => {
    const response = await axios.get(`https://api.qorbiworld.com/saakura-network/fetch-assets/${user}`);
    const saakuruAssets = response.data;
    const transformedAssets = saakuruAssets.map(asset => transformSaakuruAsset(asset, user));
    updateAssets(transformedAssets);
};