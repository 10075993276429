import React from 'react';

const HowToBuy = () => {
    return (
        <div className='darkbg'>
            <div className='section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h2 className='green-title'>Buying NFTs on Qor Baby</h2>
                        </div>
                        <div className='col-12'>
                            <ul className='steps'>
                                <li>
                                    <h3 className='green-title-small'>Create a SOL wallet</h3>
                                    <div className='content'>
                                      <div className='row'>
                                            <div className='col-md-6'>
                                                SOL is the cryptocurrency we use for purchases on Qor Baby. To keep your SOL safe, you’ll need a crypto wallet—we recommend using one called Solflare or Phantom. Just head to Solflare’s site or Planthom’s site, install the Chrome extension, and create an account.<br/>
                                                <a href="https://solflare.com" target="_blank" rel="noreferrer">Solflare</a><br/><a href="https://www.phantom.app/" target="_blank" rel="noreferrer">Phantom</a>
                                            </div>
                                            <div className='col-md-6'>
                                                <img src='/assets/img/wallet.jpg' alt='step-1'/>
                                            </div>
                                      </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 className='green-title-small'>Connect your wallet to Qor Baby</h3>
                                    <div className='content'>
                                      <div className='row'>
                                            <div className='col-md-6'>
                                                <img src='/assets/img/wallet2.png' alt='step-2'/>
                                            </div>
                                            <div className='col-md-6'>
                                                To fund your wallet, you’ll need to purchase SOL tokens. Open your wallet, tap “Deposit”, and select “Deposit option”. A new window will open where you can complete your purchase.
                                            </div>
                                      </div>
                                    </div>
                                </li>
                                <li>
                                    <h3 className='green-title-small'>Create a SOL wallet</h3>
                                    <div className='content'>
                                      <div className='row'>
                                            <div className='col-md-6'>
                                                To connect your wallet, tap “Connect Wallet” here on the site and your wallet will connect.
                                            </div>
                                            <div className='col-md-6'>
                                                <img src='/assets/img/wallet3.jpg' alt='step-3'/>
                                            </div>
                                      </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowToBuy;