import React from "react";
import { useNavigate } from "react-router";
import BundleHomePromotion from "../components/BundleHome";
import { bundlesArray } from "../utils/Global";

const MarketItem = ({ index, item }) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        const bundleId = index + 1
        navigate(`/my-bundles/${bundleId}`);
    };

    return (
        <div className="col-12 col-md-4" style={{ marginBottom: 45, position: 'relative', paddingBottom: 60 }}>
            <div className="nft-card" style={{ boxShadow: "-2px 2px 14px 1px #51E180", borderRadius: "8px", height: "100%", marginBottom: 40 }}>
                <div style={{ padding: "30px" }}>
                    <div
                        className="nft-bg"
                        style={{ backgroundImage: `url(${item.img})`, maxWidth: "100%", margin: "0 auto" }}
                    ></div>
                </div>
                <div className="content" style={{ background: "transparent", position: 'relative', textAlign: "center" }}>
                    <h4 className="fs-10 txt-green">
                        {item.name}
                    </h4>
                    <br></br>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", paddingBottom: 10, position: "absolute", left: 0, width: "100%", paddingLeft: 20, paddingRight: 20, bottom: 60 }}>
                <button
                    className="greenBtn"
                    style={{ width: "100%", marginRight: "10px", height: 50 }}
                    onClick={handleNavigate}
                >
                    Check Collection
                </button>
            </div>
        </div>
    );
};

const Bundles = () => {

    return (
        <div className="darkbg market">
            <div className="section mt-5">
                <div className="container">
                    <BundleHomePromotion></BundleHomePromotion>
                    <div className="row mt-5 align-items-stretch">
                        <h1 style={{ color: '#51E180', fontSize: '40px', fontWeight: 'bold', marginBottom: '30px' }}>Select a Bundle and Check Your Collection!</h1>
                        {bundlesArray.map((item, index) => (
                            <MarketItem key={item.id} item={item} index={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Bundles;