import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/WalletContext";
import { Toast, Sphereone } from "../utils/Global";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import WalletSelectionOverlay from "../components/WalletSelectionOverlay";
import ConnectWalletToBuy from "../components/ConnectWalletToBuy";

const Mint = () => {
  const {
    state,
    mintStatus,
    transferMint,
    getEggImage,
  } = useContext(Context);
  const { sphereOneUser } = useSphereOneProvider();
  const [code, setCode] = useState("");
  const [showConnectMessage, setShowConnectMessage] = useState(false);
  const [mint, setMint] = useState({ show: false, data: {}, amount: 1 });
  const [info, setInfo] = useState({
    image: "/assets/img/Jaguar_Silver.gif",
    title: "Your code gives you access to mint up to 1 Egg",
  });
  // let navigate = useNavigate();
  const [showCard, setShowCard] = useState(false);
  const price = 0;
  useEffect(() => {
    localStorage.removeItem('unauthorizedPath');
    if (state.user.authType === Sphereone) {
      getData();
    } else {
      if (!state.userWallet) {
        setShowConnectMessage(true)
        getData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getData = async () => {
    let res = await getEggImage();
    if (res.success) {
      setInfo({ image: res.data.imageUrl, title: res.data.message });
    }
  };
  // const getUSDConvertion = async () => {
  //   let res = await convertToUSD(mint.data.price * mint.amount.toFixed(2));
  //   if (res.success) {
  //     setPrice(res.data.value);
  //     setTimeout(() => {
  //       setShowCard(true);
  //     }, 1000);
  //   }
  // };
  const checkCode = async () => {
    let res = await mintStatus(code);
    if (res.success) {
      if (res.data._id) {
        setMint({ show: true, data: res.data, amount: 1 });
      } else {
        Toast.set({ show: true, type: "error", desc: res.data.message });
      }
    }
  };

  return (
    <div className="darkgreen-bg">
      {sphereOneUser !== null && <WalletSelectionOverlay></WalletSelectionOverlay>}
      {showConnectMessage && <ConnectWalletToBuy></ConnectWalletToBuy>}
      <div className="section">
        <div className="container">
          {mint.show ? (
            <div className="row single-baby">
              <div className="col-12 col-md-7">
                <div className="row media-container">
                  <div className="col-md-12 col-lg-10">
                    <img src={info.image} style={{ width: "100%" }} alt="Mint Mint" />
                    <h4 className="attribute-title">
                      As soon as you finish minting contact an admin on discord
                      to get the Qor Owner status. To see your Egg click on{" "}
                      <a
                        href="/my-assets"
                        rel="noreferrer"
                        target="_blank"
                        style={{ color: "#51e180" }}
                      >
                        My Assets
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <h2 className="baby-name">Mint Code: {mint.data.code}</h2>
                <div className="baby-price">
                  {mint.data.price < 1
                    ? "Free Mint"
                    : "Mint Price: " +
                    (mint.data.price * mint.amount).toFixed(2) +
                    "SOL"}
                </div>
                <div className="tab-container">
                  <div className="tab-body">
                    <h4 className="attribute-title">{info.title}</h4>
                    <div className="amount-container">
                      <i
                        className="fa fa-minus"
                      // onClick={() =>
                      //   mint.amount > 0
                      //     ? setMint({ ...mint, amount: mint.amount - 1 })
                      //     : null
                      // }
                      />
                      <input
                        disabled
                        type="number"
                        value={mint.amount}
                        name="amount"
                        onChange={(val) =>
                          setMint({
                            ...mint,
                            amount:
                              val.target.value > 0
                                ? parseInt(val.target.value)
                                : 0,
                          })
                        }
                        min="0"
                      />
                      <i
                        className="fa fa-plus"
                        onClick={
                          () => { }
                          // setMint({ ...mint, amount: mint.amount + 1 })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="greenBtn"
                    onClick={async () => {
                      if (state.userWallet && state.userWallet.string) {
                        // if (state.userWallet.name === "immutablex") {
                        let res = await transferMint(
                          state.userWallet.string,
                          code
                        );
                        if (res.success) {
                          Toast.set({
                            show: true,
                            type: "success",
                            desc: res.data,
                          });
                        }
                        //   } else {
                        //     submit();
                        //   }
                      } else {
                        setShowConnectMessage(true);
                      }
                    }}
                  >
                    Mint Now
                  </button>
                  <div className="col-12 mt-3">
                    <p style={{ textAlign: "center", color: "#51e180", fontSize: 16 }}>
                      Your egg will mint directly into your logged in wallet hosted by {" "}
                      <a
                        href="https://wallet.sphereone.xyz/LandingScreen"
                        style={{ color: "#51e180", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SphereOne
                      </a>
                      . {" "}To connect your wallet to MetaMask follow {" "}
                      <a
                        href="https://sphereonehelp.zendesk.com/hc/en-us/articles/20217270166171-Listing-Assets-on-IMX-Marketplaces-from-your-SphereOne-Account"
                        style={{ color: "#51e180", textDecoration: "underline" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        these instructions
                      </a>
                      .
                    </p>
                  </div>
                  {/* <button className="greenBtn" onClick={() => submit()}>
                    Select and Buy
                  </button> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <h2 className="green-title">
                  Enter your minting code to start your mint process.
                </h2>
              </div>
              <div className="col-12" style={{ textAlign: "center" }}>
                <div className="btn-submit-cont">
                  <input
                    placeholder="Enter Code"
                    value={code}
                    onChange={(val) => setCode(val.target.value)}
                    name="code"
                    className="codeinput"
                    onKeyPress={(val) =>
                      val.code === "Enter" ? checkCode() : null
                    }
                  />
                  <div className="btn-submit" onClick={() => checkCode()}>
                    <i className="fa fa-paper-plane" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showCard ? (
        <div className="position-fixed alert-container">
          <div className="toast-container" style={{ width: 500, height: 640 }}>
            <div className="toast-content" style={{ width: "100%" }}>
              <form name="tokenform" id="tokenform">
                <iframe
                  // https://qorgaminguat.securepayments.cardpointe.com/pay
                  src={`https://qorgaming.securepayments.cardpointe.com/pay?total=${price}&mini=1&cf_hidden_wallet=${state.userWallet.string}&cf_hidden_code=${mint.data.code}&cf_hidden_amount=${mint.amount}&cf_hidden_isMint=true`}
                  // frameborder="0"
                  style={{ width: "100%", height: 520 }}
                  title="qorgamin"
                ></iframe>{" "}
                <input type="hidden" name="mytoken" id="mytoken" />
              </form>
              <button
                style={{ marginTop: 10 }}
                className="greenBtn"
                onClick={() => setShowCard(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Mint;
