import React from 'react';
import { useSphereOneProvider } from "../utils/SphereOneHooks";

const StartMissionsMessage = () => {

    const { setStartMission } = useSphereOneProvider();
    
    const handleButtonClick = () => {
        window.open("https://quest.playmana.gg/quests");
        setStartMission(false)
    };

    return (
        <div className="missions">
            <div className="missions-content">
                <p style={{color:"black"}}>
                    Introducing the first Qorbi World game “One Tap”. Start earning $QORBI today with the One Tap game missions.
                    Join thousands of gamers as we explore the One Tap Training Grounds a PVE training area for future One Tap champions.
                    Earn $QORBI everyday just for playing and leveling up in the One Tap game.
                </p>
                <button className="greenBtn" onClick={handleButtonClick}>
                    Start Missions
                </button>
            </div>
        </div>
    );
};

export default StartMissionsMessage;
