import React, { useState, useEffect } from "react";
import axios from "axios";
import defaultImage from "../assets/img/placeholder-qorbaby.png";

const QorShop = () => {
    const defaultSettings = {
        "Hair Color": "",
        "Clothes": "",
        "Eyebrows": "",
        "Eyes Type": "",
        "Mouth": "",
        "Skin color": "",
        "Sex": "",
    };

    const dropdownOptions = {
        "Hair Color": [
            "Black Hair",
            "Blue Hair",
            "Salt & Pepper Hair",
            "Copper Hair",
            "Pink Hair",
            "Red Hair",
            "Green Hair",
            "Lemon Hair",
        ],
        // "Hair Style": [],
        "Clothes": [
            "Military",
            "Martian",
            "Viking",
            "Cowboy",
            "Knight",
            "King",
            "Ninja"
        ],
        "Eyes Type": [
            "Oval",
            "Sleepy",
            "Villain",
            "Dots",
            "Hero",
            "Pop",
            "Round",
            "Double"
        ],
        "Eyebrows": [
            "Angry",
            "Normal",
            "Simple",
            "Sweet",
            "Thick",
            "Swagger",
            "Thin",
            "Classy",
            "Arched",
            "Short"
        ],
        "Mouth": [
            "Funny",
            "Cute",
            "Smirk",
            "Tough",
            "Grin",
            "Sweet",
            "Sad",
            "Serious",
            "Normal"
        ],
        "Skin color": [
            "001",
            "002",
            "003",
            "004"
        ],
        "Sex": [
            "Female",
            "Male",
        ]
    }
    const [selectedSettings, setSelectedSettings] = useState(defaultSettings);
    const [nameSettings, setNameSettings] = useState("");
    const [dropdownData, setDropdownData] = useState(dropdownOptions)
    const [currentQorbs, setCurrentQorbs] = useState([])
    const [isRandom, setIsRandom] = useState(true)
    const [errorMessage, setErrorMessage] = useState("");

    const handleSettingChange = (settingName, value) => {
        setSelectedSettings(prevSettings => ({
            ...prevSettings,
            [settingName]: value
        }));
        if (settingName === "Hair Color") {
            getAttributes(value)
        }
    };
    const handleNameChange = (value) => {
        setNameSettings(value);
    };
    const createQorb = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const badgeId = urlParams.get('badge');
        const badgeWallet = urlParams.get('wallet');
        const babyId = currentQorbs[0]._id;
        const babyImage = currentQorbs[0].image;
        if (!nameSettings) {
            setErrorMessage("Name is required.");
            return;
        } else {
            const nameRegex = /^[a-zA-Z0-9]*$/;
            if (!nameRegex.test(nameSettings)) {
                setErrorMessage("Only letters and numbers are acceptable for the name.");
                return;
            } else {
                setErrorMessage("");
            }
        }

        try {
            console.log(nameSettings);
            const response = await axios.get(`https://api.qorbiworld.com/qorbs/check-name?name=${nameSettings}`);
            if (response.data.available) {
                window.open(`/qorbaby-confirmation?id=${babyId}&name=${nameSettings}&image=${babyImage}&badge=${badgeId}&wallet=${badgeWallet}`);
            } else {
                setErrorMessage(response.data.message || "Name is not available.");
            }
        } catch (error) {
            setErrorMessage("Only letters and numbers are acceptable for the name");
        }
    };

    const resetSettings = () => {
        setSelectedSettings(defaultSettings);
        setCurrentQorbs([])
    };

    const selectRandomSettings = () => {
        setIsRandom(true)
        const settingsKeys = Object.keys(defaultSettings).filter(setting => setting !== "Hair Style");
        const randomSettings = {};

        for (let i = 0; i < 2; i++) { //Only 2 random settings, too many randoms and it may take forever to find a match
            let randomSetting;
            do {
                randomSetting = settingsKeys[Math.floor(Math.random() * settingsKeys.length)];
            } while (randomSettings[randomSetting]);

            const randomOptions = dropdownData[randomSetting];
            const randomOption = randomOptions[Math.floor(Math.random() * randomOptions.length)];
            randomSettings[randomSetting] = randomOption;

            if (randomSetting === "Hair Color") {
                getAttributes(randomOption)
            }
        }
        setSelectedSettings({
            ...defaultSettings,
            ...randomSettings
        });
    };

    const getRandomMatch = async () => {
        try {
            const attributes = {};
            for (const [key, value] of Object.entries(selectedSettings)) {
                if (value && (key !== "Hair Style" && key !== "Hair Color")) {
                    attributes[key] = value;
                } else if (value && key === "Hair Color") {
                    if (selectedSettings["Hair Style"]) {
                        attributes[value] = selectedSettings["Hair Style"];
                    } else {
                        attributes[value] = dropdownData["Hair Style"][0];
                    }
                }
            }
            const requestBody = {
                "attributes": Object.entries(attributes).map(([name, value]) => ({
                    "name": name,
                    "value": value
                }))
            };
            const response = await axios.post(`https://api.qorbiworld.com/qorbs/get-random-match`, requestBody, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json'
                }
            });
            if (response.data.matches.length === 0 && isRandom) {
                selectRandomSettings()
            } else {
                setIsRandom(false)
            }
            setCurrentQorbs(response.data.matches)
        } catch (error) {
            console.error("Error getting random match:", error);
        }
    }

    const handleImageClick = (qorbIndex) => {
        const clickedImage = currentQorbs[qorbIndex];
        const newQorbs = [...currentQorbs];
        newQorbs[qorbIndex] = newQorbs[0];
        newQorbs[0] = clickedImage;
        setCurrentQorbs(newQorbs);
    };

    const getAttributes = async (hairColor) => {
        try {
            const response = await axios.get(`https://api.qorbiworld.com/qorbs/get-attributes`);
            if (hairColor && response.data[hairColor]) {
                dropdownOptions["Hair Style"] = response.data[hairColor];
                setDropdownData(dropdownOptions)
                setSelectedSettings(prevSettings => ({
                    ...prevSettings,
                    "Hair Style": ""
                }));
            }
        } catch (error) {
            console.error("Error fetching attributes:", error);
        }
    }

    useEffect(() => {
        selectRandomSettings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getRandomMatch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSettings])
    return (
        <div>
            <div className="qor-shop-container" style={{ marginTop: "100px" }}>
                <div className="qor-shop-left-container" style={{ position: "relative" }}>
                    <>
                        <img src={currentQorbs[0]?.image || defaultImage} alt="Qorb" className="qor-shop-qorb-image" />
                        {currentQorbs[0]?.image && (
                            <button className="btnTransparent" onClick={createQorb} style={{
                                position: "absolute",
                                left: "50%",
                                transform: "translateX(-50%)",
                                bottom: "10px",
                            }}>Ready To Mint</button>
                        )}
                    </>
                </div>
                {window.innerWidth < 1000 && (
                    <div className="qor-shop-third-container">
                        {currentQorbs.length === 0 ? (
                            Array.from({ length: 4 }).map((_, index) => (
                                <img key={index} src={defaultImage} alt="Default Qorb" className="qor-shop-qorb-image" />
                            ))
                        ) : (
                            currentQorbs.slice(1, 5).map((qorb, index) => (
                                <img key={index} src={qorb?.image} alt={index + 2} onClick={() => handleImageClick(index + 1)} />
                            ))
                        )}
                    </div>
                )}
                <div className="qor-shop-right-container">
                    <p className="qor-shop-name-text">{nameSettings ? `Qorb Name: ${nameSettings}` : "Qorb"}</p>
                    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                    <p className="qor-shop-settings-text">Your Qorb Minting Badge allows you to select the settings for your Qorb</p>
                    {window.innerWidth < 1000 && (
                        <div className="qor-shop-buttons">
                            <button className="btnTransparent" onClick={getRandomMatch}>Find More</button>
                            <button className="greenBtn" onClick={resetSettings}>Reset Settings</button>
                            <button className="btnTransparent" onClick={selectRandomSettings}>Randomize</button>
                        </div>
                    )}
                    <div className="qor-shop-dropdown-container">
                        {Object.keys(selectedSettings).map((setting, index) => (
                            setting !== "Name" &&
                            <div className="setting-container" key={index}>
                                <label className="setting-label">{setting}</label>
                                <select
                                    value={selectedSettings[setting]}
                                    onChange={(e) => handleSettingChange(setting, e.target.value)}
                                >
                                    <option value="" disabled>Select {setting}</option>
                                    {dropdownData[setting].map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))}
                                </select>
                            </div>
                        ))}
                        <div className="setting-container">
                            <label className="setting-label">Name</label>
                            <input
                                type="text"
                                value={nameSettings}
                                onChange={(e) => handleNameChange(e.target.value)}
                                placeholder="Enter Name"
                            />
                        </div>
                    </div>
                    {window.innerWidth > 1000 && (
                        <div className="qor-shop-buttons">
                            <button className="btnTransparent" onClick={getRandomMatch}>Find More</button>
                            <button className="greenBtn" onClick={resetSettings}>Reset Settings</button>
                            <button className="btnTransparent" onClick={selectRandomSettings}>Randomize</button>
                        </div>
                    )}
                </div>
            </div>
            {window.innerWidth > 1000 && (
                <div className="qor-shop-third-container">
                    {currentQorbs.length === 0 ? (
                        Array.from({ length: 4 }).map((_, index) => (
                            <img key={index} src={defaultImage} alt="Default Qorb" className="qor-shop-qorb-image" />
                        ))
                    ) : (
                        currentQorbs.slice(1, 5).map((qorb, index) => (
                            <img key={index} src={qorb?.image} alt={index + 2} onClick={() => handleImageClick(index + 1)} />
                        ))
                    )}
                </div>
            )}
        </div >
    );
};

export default QorShop;
