import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorShowing from "../components/ErrorShowing";
import LoadingTextLootbox from '../assets/svg/LoadingTextLootbox';
import lootbox from '../assets/img/Treasure_Chest_Shine.png';
import closedChest from '../assets/sounds/closedChest.mp3'
import axios from 'axios';
const ethereumService = require('../context/EthereumService');

const OpenLootBox = () => {
    const [showVideo, setShowVideo] = useState(false);
    const [showLootbox, setShowLootbox] = useState(true);
    const [lootboxes, setLootboxes] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);
    const [response, setResponse] = useState(false);
    const [skinInfo, setSkinInfo] = useState(null);
    const [selectLootbox, setSelectLootbox] = useState(false);
    const [loadingLootbox, setLoadingLootbox] = useState(false);
    const lootboxRef = useRef(null);
    const videoRef = useRef(null);
    const savedLootboxes = JSON.parse(localStorage.getItem('lootboxes')) || [];
    const navigate = useNavigate();

    const handleLootboxClick = () => {
        if (response === true) {
            setShowLootbox(false);
            setShowVideo(true);
        } else {
            new Audio(closedChest).play();
        }
    };

    const handleVideoLoad = () => {
        if (lootboxRef.current) {
            lootboxRef.current.style.transform = 'scale(1)';
        }
    };

    const transferLootbox = async (boxToOpen) => {
        setLoadingLootbox(true)
        setSelectLootbox(false)
        if (boxToOpen.status === 'imx') {
            try {
                const apiUrl = `https://api.qorbiworld.com/v1/nft/open-loot-box/${boxToOpen.tokenId || boxToOpen.token_id}`;
                const resTrade = await axios.patch(apiUrl);
                if (resTrade.data.status === 'success') {
                    setLoadingLootbox(false)
                    setResponse(true)
                    setSkinInfo(resTrade.data.metadata)
                    setErrorMsg("Skins Found, click the lootbox to open");
                } else {
                    setLoadingLootbox(false)
                    setErrorMsg("Lootbox opening went wrong, contact us");
                    setTimeout(() => {
                        setErrorMsg(null);
                    }, 4000);
                }
            } catch (e) {
                console.error("Lootbox opening went wrong: ", e);
                setErrorMsg("Lootbox opening went wrong: ", e);
                setTimeout(() => {
                    setErrorMsg(null);
                }, 4000);
                return;
            }
        } else {
            const transferEthereum = await ethereumService.transferAsset(boxToOpen.user, '0xAb96C6ec687d0211981C0D800c25706b70047560', boxToOpen.token_id)
            console.log(transferEthereum);
            if (transferEthereum.receipt) {
                await axios.patch('https://api.qorbiworld.com/saakura-network/open-lootbox', {
                    hash: transferEthereum.receipt.transactionHash,
                    walletAddress: boxToOpen.user,
                    tokenId: parseInt(boxToOpen.token_id),
                });
                setLoadingLootbox(false)
                setErrorMsg("Skins Found, you will see it in your assets in a moment");
                setTimeout(() => {
                    navigate('/my-assets');
                }, 5000);
            } else {
                console.log(transferEthereum.error);
                setLoadingLootbox(false)
                setErrorMsg("Lootbox opening went wrong, contact us");
                setTimeout(() => {
                    setErrorMsg(null);
                }, 4000);
            }
        }
    }

    const getRarityColor = (rarity) => {
        switch (rarity) {
            case 'Common':
                return '#AFAFAF';
            case 'Rare':
                return '#53D189';
            case 'Epic':
                return 'purple';
            case 'Legendary':
                return 'orange';
            default:
                return '#000000';
        }
    };

    useEffect(() => {
        if (savedLootboxes.length > 0) {
            setLootboxes(savedLootboxes);
            localStorage.removeItem('lootboxes');
        } else {
            navigate('/my-assets');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{ color: '#fff', textAlign: 'center', marginTop: '75px' }}>
            <div
                style={{
                    margin: '20px auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                {selectLootbox && (
                    <div className="popup" style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }}>
                        <div style={{
                            background: '#fff',
                            border: '3px solid green',
                            padding: '20px',
                            borderRadius: '5px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1), 0 0 10px rgba(0, 255, 64, 0.8)',
                        }}>
                            <ul>
                                {lootboxes.map((item, index) => (
                                    <li style={{ cursor: 'pointer', padding: '10px', borderBottom: '1px solid green', color: "green" }} key={index} onClick={() => transferLootbox(item)}>
                                        {item.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {loadingLootbox && <LoadingTextLootbox></LoadingTextLootbox>}
                {errorMsg && <ErrorShowing errorText={errorMsg} backColor={"#BB256E"} />}
                {showLootbox && !showVideo && (
                    <div>
                        <img
                            ref={lootboxRef}
                            src={lootbox}
                            alt="Lootbox"
                            style={{
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease-in-out',
                                transform: 'scale(1)',
                                width: '100%',
                                maxWidth: '800px',
                            }}
                            onClick={() => {
                                handleLootboxClick();
                                if (lootboxRef.current) {
                                    lootboxRef.current.style.transform = 'scale(1.1)';
                                    setTimeout(() => {
                                        lootboxRef.current.style.transform = 'scale(1)';
                                    }, 300);
                                }
                            }}
                        />
                        <h2>SKIN LOOTBOX</h2>
                        <p style={{ margin: '0 20px' }}>Open it and get a fantastic in-game skin. Can take up to 2 min to find the perfect skin for you</p>
                        <button
                            style={{
                                marginTop: '20px',
                                padding: '15px 30px',
                                fontSize: '18px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setSelectLootbox(true)}
                        >
                            Open LootBox
                        </button>
                    </div>
                )}
                {showVideo && (
                    <div>
                        {/* Video */}
                        <video
                            id="video"
                            autoPlay
                            controls={false}
                            onEnded={() => setShowVideo(false)}
                            ref={videoRef}
                            style={{ width: '100vh', maxHeight: '700px' }}
                            onLoadedData={handleVideoLoad}
                        >
                            <source src={'https://d2ceeuvjz02d1t.cloudfront.net/ChestPR02+(1).mp4'} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                )}
                {!showLootbox && !showVideo && (
                    <div style={{ textAlign: 'center' }}>
                        <img src={skinInfo.image_url} alt={skinInfo.name} style={{ width: '100%', maxWidth: '600px' }} />
                        <h2 style={{ color: getRarityColor(skinInfo.rarity), fontSize: '36px', marginBottom: '10px' }}>{skinInfo.name}</h2>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            <div className="skin-info-element" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: '300px' }}>
                                <span className="element-name" style={{ fontWeight: 'bold', fontSize: '18px', color: getRarityColor(skinInfo.rarity) }}>Rarity:</span>
                                <span className="element-value" style={{ color: getRarityColor(skinInfo.rarity), fontSize: '18px' }}>{" " + skinInfo.rarity}</span>
                            </div>
                            <div className="skin-info-element" style={{ margin: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', width: '300px' }}>
                                <span className="element-name" style={{ fontWeight: 'bold', fontSize: '18px' }}>Weapon:</span>
                                <span className="element-value" style={{ fontSize: '18px' }}>{" " + skinInfo.weapon}</span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OpenLootBox;
